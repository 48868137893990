import React from 'react'
import { Form } from 'semantic-ui-react'
import { error, info, GENERAL} from '../dashboard/common'
import { FormInput,  FormGroup, LabelInfo} from '../basic/input/form'


export default ({dashboard, merged, level, set, fields}) => {
  return (
    <Form>
      <Form.Group inline widths='equal' >
          <FormInput {...info(GENERAL, fields.SCHEDULE)} {...error(dashboard,fields.SCHEDULE, level)} label='Schedule' placeholder='Schedule Expression' value={merged[fields.SCHEDULE]} onChange={(e, { value }) => set(fields.SCHEDULE, value)}n/>
          <FormGroup style={{float: 'right', padding: '0 1em 0 50px'}} {...error(dashboard,fields.STATE, level)}>
              <LabelInfo style={{paddingRight: '10px' }} label='State' >{info(GENERAL, fields.STATE).info}</LabelInfo>   
              <Form.Radio label='Enabled' value='ENABLED' checked={merged[fields.STATE] === 'ENABLED'} onChange={(e, { value }) => set(fields.STATE, value)} />
              <Form.Radio label='Disabled' value='DISABLED' checked={merged[fields.STATE] === 'DISABLED'} onChange={(e, { value }) => set(fields.STATE, value)} />
          </FormGroup>
      </Form.Group>
    </Form>
  )
}