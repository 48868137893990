import React from 'react'

import * as Actions from '../actions' 
import HeadersDoc from './headers'

//import { COMMON_FIELDS } from '../../../dashboard/common'

export default {
    [Actions.INSTRUMENT] : {
        [Actions.FIELDS.HEADERS] : (<HeadersDoc />),
        // ... More fields
    }
}