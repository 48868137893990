
import data from '../data'

import { SIGNED_IN } from './actions'

const initialState = {
  isEditable : data.isEditable,
  signedIn : false,
  user : null
}




export default (state = initialState, action) => {
    switch (action.type) {

     case 'SIMPLE_ACTION':
        return {
          result: action.payload
        }
     case 'TOGGLE_EDITABLE':
      return {
        ...state,
        isEditable: !state.isEditable
      }
     case SIGNED_IN:
        return {
          ...state,
          signedIn: true,
          user: action.payload
        }
     default:
      return state
    }
   }