import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Icon, Button } from 'semantic-ui-react'
//import './input.css'
//import * as Util from '../../../util'


class CollapsibleSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // toggle box is closed initially
      isOpened: false,
    };
    // http://egorsmirnov.me/2015/08/16/react-and-es6-part3.html
    this.toggleBox = this.toggleBox.bind(this);
  }

  toggleBox() {
    this.setState(oldState => ({ isOpened: !oldState.isOpened }));
  }

  render = () => {
    const {children, style, title, other} = this.props
    const { isOpened } = this.state
    return (
      <div style={{paddingTop:'20px'}} className='segment-with-collapse'>
          <div style={{display:'block'}}>
            <h3 style={{display:'inline-block', paddingRight: '10px'}}>{title}</h3>
            <Icon onClick={this.toggleBox} link  name='eye' />
          </div>
          {isOpened && children && (
            <Segment props={{...other}} style={{...style, marginTop: 0}}>
                {children}
            </Segment> 
          )}
      </div>
    )
  }
}

export default connect(null, null)(CollapsibleSegment)

