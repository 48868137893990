import * as util from '../../../util'
import { COMMON_FIELDS } from '../../dashboard/common'
import * as DashboardActions from '../../dashboard/actions'
import * as InstrumentActions from '../actions'
import * as Services from '../../../services/services'
import * as Config from '../../../config'
import * as Actions from '../actions'
import Instruments from '../instruments'

export const INSTRUMENT  = 'group'
export const SAVE_DATA   = `${INSTRUMENT}.saveData`

// This instruments S3 bucket folder - each instrument can have its own folder in the app-support-myer bucket
export const S3_FOLDER = 'endpoint'

export const FIELDS = {
    DESCRIPTION : COMMON_FIELDS.DESCRIPTION,
    STATE   : COMMON_FIELDS.STATE,
}

/**
 * Delete from DDB table
 * 
 * @param {*} parent 
 * @param {*} insp 
 */
export function deleteInsp(parent, insp) {
	return (dispatch, getState) => {

		dispatch({
			type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
		})

		// Construct REST call body
        const body = {
			"name" : insp.name,
            "env": parent.env,
            "instrument": INSTRUMENT
        }

		// Fire rest call to APIGateway
		var pathParams = {
			//This is where path request params go. 
		};
		// Template syntax follows url-template https://www.npmjs.com/package/url-template
		var pathTemplate = `/${Config.API_STAGE}/group-manage`
		var method = 'DELETE';
		
		var additionalParams = {
			//If there are query parameters or headers that need to be sent with the request you can add them here
			headers: {
				/*"Accept": "text/plain",*/
				"Content-Type": "application/json"
			},/*
			queryParams: {
				param0: '',
				param1: ''
			}*/
		};
				
		Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
			.then(function(result){
				// Changes committed to backend - do cleanup - the changes should be recieved from the backend 
				dispatch({
					type : InstrumentActions.CONFIRM_DELETE_INSP,
				})
			}).catch( function(result){
				//This is where you would put an error callback
				console.log('failed')
				dispatch({
                    type : DashboardActions.API_EXCEPTION,
                    payload : { fault: result, item: insp }
                })
			}
		);
		
	}
    /*
    return Actions.deleteInspS3(parent, insp,(s3name, raw, app) => {
        return {
            meta: {
                name : s3name,
                instrument : INSTRUMENT // This instrument
            }
        }
    },{})
    */
}

/**
 * Save direct to DDB table
 * 
 * @param {*} parent 
 * @param {*} item 
 */
export function save(parent, item) {

    return (dispatch, getState) => {

		dispatch({
			type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
        })

        // Dispatch rest call - which adds to s3 or updates s3
        const level = util.level(item.name)

        const state = getState().dashboard
        const changed = state.inspection.levels[level-1].save.changed 

        const name = changed.name !== undefined ? `${parent.name}.${changed.name}` : item.name
        
        // Construct REST call body
		const body = {
			"name" : name,
            "env": parent.env,
            "instrument": INSTRUMENT
        }

        // Fire rest call to APIGateway
        var pathParams = {
            //This is where path request params go. 
        };
        // Template syntax follows url-template https://www.npmjs.com/package/url-template
		//Dev var pathTemplate = '/dev/app-manage'
		var pathTemplate = `/${Config.API_STAGE}/group-manage`
        var method = 'PUT';
        
        var additionalParams = {
            //If there are query parameters or headers that need to be sent with the request you can add them here
            headers: {
                /*"Accept": "text/plain",*/
                "Content-Type": "application/json"
            },/*
            queryParams: {
                param0: '',
                param1: ''
            }*/
        };

		//const axios = require('axios')
		//axios.defaults.withCredentials = false;
        Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(function(result){
                // Changes committed to backend - do cleanup - the changes should be recieved from the backend 
                dispatch({
                    type : InstrumentActions.CLEANUP_INSP,
                    payload : { parent, item: item, name: name }  // name will be different, if this is a new inspection --- cleanup needs to handle
                })

                dispatch({
                    type : InstrumentActions.CHANGE_INSP_MODE,
                    payload : { parent, insp: item, mode: DashboardActions.MODES.VIEW }
                })
            }).catch( function(result){
                console.log('failed')
                dispatch({
                    type : DashboardActions.API_EXCEPTION,
                    payload : { fault: result, item: item }
                })
            });
    }
 
}

// Performs action on all children inspections associated with this group
export function runInsp(parent, item, compFn = null) {   
    return (dispatch, getState) => {
        item.inspections.forEach(insp => {
            if (insp.type !== 'group') {
                dispatch(Instruments.actions[insp.type].runInsp(parent, insp))        
            }        
        });
    }
}

export function stopInsp(parent, item, compFn = null) {   
    return (dispatch, getState) => {
        item.inspections.forEach(insp => {
            if (insp.type !== 'group') {
                dispatch(Instruments.actions[insp.type].stopInsp(parent, insp))        
            }        
        });
    }
}







