import React from 'react'
import CodeTag from 'components/basic/CodeTag'




export default () => (
    <div className='doc' style={{padding:'20px'}}>
<h2>AMS Monitoring Dashboard</h2>

<h3>Table of Contents</h3>

<h3>Overview</h3>

<p>The AMS Monitoring Dashboard is a Tool, that provides:</p>

<ul>
<li>A single view of the state/health of all systems managed/supported by Myer's Application Management Services Group</li>
<li>A number of instruments that inspect one or more aspects (inspections) of each system </li>
<li>An Edit mode for adding/configuring applications and inspections</li>
<li>Infrastructure/Framework for easily developing more instruments</li>
<li>Filtering based on applications, environments and instruments, for a partial view of the vast number of systems</li>
<li>A multi level view of all systems, where each system, composed of multiple sub systems, can be viewed and monitored on its sub system level</li>
<li>Multiple applications can be opened simultaneously to view/monitor the state of its subsystems </li>
<li>Mobile app with push notifications, for any failure events - (Currently only the IOS App is fully developed, Please see Harry.theofanous@myer.com.au for installing on an Apple device, will eventually be available on the App Store)</li>
<li>Context sensitive help/documentation, while in edit Mode</li>
<li>Capture each inspection execution as a timestamp measurement, of one or more metrics, to gauge performance over time  </li>
<li>Authentication/Login via Myer's Team member Azure Active Directory using SAML. The Authentication/SAML module has been developed as a separate generic component, for use by this Tool as well as any future Tools and/or Applications that require secure Team Member access </li>
</ul>

<p>The Dashboard presents a Table of the Applications, uniquely identified by the applications name and environment. The tool will not allow multiple entries with the same name, unless the environment specified is different</p>

<p>Currently there are 3 environments to select from, and these correspond to the AWS accounts shown below:</p>

<table>
<thead>
<tr>
  <th>Environment</th>
  <th>Account</th>
  <th>Account #</th>
</tr>
</thead>
<tbody>
<tr>
  <td>Dev</td>
  <td>myer-dev</td>
  <td>763226548626</td>
</tr>
<tr>
  <td>Test</td>
  <td>myer-test</td>
  <td>712164089289</td>
</tr>
<tr>
  <td>Prod</td>
  <td>myer-prod</td>
  <td>844267585196</td>
</tr>
</tbody>
</table>

<p>The Tool is agnostic, to the AWS accounts and operates across all accounts making use of AWS cross account access mechanisms, see <a href="#crossac">Cross Account Access</a>. More Environments/Accounts can be easily added in the future. The Tool may also be used for monitoring systems outside the AWS cloud, such as our POS systems, by developing appropriate instruments for this purpose.</p>

<p>The following diagram provides a quick overview of the General architecture of the Dashboard Tool</p>

<p><img src="images/AMSDashboard-Overview.jpg" alt="Architecture" /></p>

<p>For a better view of the diagram, and newer changes refer to this <a href="https://drive.google.com/open?id=14Gd2XsmPRZQSIzrcGSGcFRD__i6kszPS" target="_blank">link</a></p>

<p>The LHS shows the components that are common for all instruments. The RHS shows the components for the 'endpoint' instrument, which can be quite different for different instruments. Refer to the context sensitive info for each instrument. An instrument can be complex i.e. use several lambdas, several AWS Cloudwatch events etc, or as simple as just one Lambda, which is not triggered periodically, but just one off, by a button in the view panel of an inspection (View panel is displayed when the tool is in View Mode), when clicked, takes a measurement which can be compared to previous measurements taken ad-hoc before.</p>

<p>The main concept of the tool are the instruments. This is explained in the following section.</p>

<h3>Instruments</h3>

<p>The instruments are self contained modules (plugins) that perform a particular measurement or check on an applications subsystems. Such as, for example, performing an end to end request  across the internet, on an applications API endpoint, to determine the health of the applications backend systems. </p>

<p>The following instruments have, been developed and briefly described here. </p>

<table>
<thead>
<tr>
  <th>Instrument</th>
  <th>Description</th>
</tr>
</thead>
<tbody>
<tr>
  <td>Endpoint</td>
  <td>Given an endpoint configuration, and schedule, perform a request to the endpoint testing its availability and response times/latency</td>
</tr>
<tr>
  <td>EC2-Check</td>
  <td>" , get the number of Bastions and EC2 instances running at each iteration</td>
</tr>
<tr>
  <td>EC2-Status-Check</td>
  <td>" , check the stability of the EC2 instances, detects any EC2's going down, or coming up at any time and detects restarts (of same EC2 instance)</td>
</tr>
</tbody>
</table>

<p>For more detail on each instrument, click the context sensitive info icon, when editing/creating an inspection. An inspection is created as follows</p>

<h4>Create an Inspection</h4>

<p>In the Top bar, click the Edit icon <img src="images/bar.jpg" alt="Top Bar" />, select the Edit icon</p>

<p>This will place the Dashboard into Edit mode. Click the edit icon, on the application entry that you wish to add an inspection to.
The 'MyTicketing' for 'Test' environment, has been selected as shown below</p>

<p><img src="images/add-inspection.jpg" alt="Add Inspection" /></p>

<p>The applications, inspections will be listed as shown, with a large <img src="images/plus.jpg" alt="plus" /> icon. Press this icon to create a new 
instrument. A form for the 'endpoint' instrument, which is the default instrument, will be presented. The instrument can be changed, by clicking on the <em>instrument</em> dropdown menu. This will change the form accordingly, for the selected instrument. Detail information on the instrument can be obtained, by click info icon at the far end of the display, see below</p>

<p><img src="images/instrument-info.jpg" alt="Instrument Info" /></p>

<p>The instrument label for the instrument dropdown, also has a small info icon. Clicking will provide advanced general information on instruments, to facilitate and help with building new instruments.</p>

<p>The form fields, may also have context sensitive help information. This is also indicated by a small info icon e.g. <img src="images/info.jpg" alt="Info" /></p>

<p>Once a form is saved, it will stored in an S3 bucket. Each inspections configuration, is stored as a separate and versioned S3 object. In the event, that an inspection is deleted, it can be recovered from the saved version</p>

<blockquote>
  <p>Note: The Tool is designed to perform tasks mostly asynchronously, and when inspections are created and/or edited and saved, it may take time for the change to be updated. It will update in a few seconds, while the change propagates to the back-end and the front-end polling picks up the change, which can be up to 5 seconds. Status changes will be faster in the future, when the DynamoDB table will be refined to use the streaming DynamoDB API. </p>
</blockquote>

<p>Once an inspection has been created, it can easily be modified and saved again. Before an inspection is saved it will be validated, and each field that failed validation will be highlighted</p>

<blockquote>
  <p>Once a form is open for editing, and one or more fields, have been changed, the tool will not allow you to open another inspection, until the changes are either saved, or discarded. Changes can be discarded by clicking the edit icon (RHS) of another inspection or application, a confirmation dialogue will be displayed to confirm.</p>
  
  <p>Confirmation dialogues, have both a Yes and No Button, however the NO button is not visible until you hover over it. (Nature of component used, could change in future)</p>
</blockquote>

<p>An application is created similarly be using the <img src="images/plus.jpg" alt="plus" /> below the Applications </p>

<p><img src="images/create-application.jpg" alt="Instrument Info" /></p>

<h3>Filtering</h3>

<p>TODO ... Describe filtering in URL, can copy/bookmark URL with query parameters ....</p>

<h3>Lambdas</h3>

<p>The following Lambdas, comprise the backbone of the AMSDashboard system.</p>

<table>
<thead>
<tr>
  <th>Lambda</th>
  <th>Responsibility</th>
</tr>
</thead>
<tbody>
<tr>
  <td>s3-manage</td>
  <td>Add/Delete and Update Inspection Configurations in S3</td>
</tr>
<tr>
  <td>app-manage</td>
  <td>Add/Delete and Update Application entries in DDB Table</td>
</tr>
<tr>
  <td>ddb-state-consistency</td>
  <td>Update Application (parent) status if any of its inspections (children) change status</td>
</tr>
</tbody>
</table>

<h3>Lambda Layers</h3>

<p>The Lambda layers are shared re-useable library code </p>

<p><img src="/images/lambda-layers.jpg" alt="Lambda Layers" /></p>

<h3><a name='crossac'></a>Cross Account</h3>

<p>Cross Account access and how it works in the AMSDashboard, is a more advanced subject to be described in detail here. But briefly works as follows. For the <em>endpoint-check</em> Lambda, to be able to access the API Gateway or direct to Lambda endpoints that exist in a different AWS account, to the account it is running in. It must assume a role, which is defined in the other account. This role must, be configured to trust the <em>endpoint-check</em> lambda, the <em>endpoint-check's</em> ARN is specified in the roles trust relations. </p>

<p>For each AWS account, that the <em>endpoint-check</em> Lambda may need to access, is configured in its config yml file, per environment (An environment corresponds to an AWS Account). The Lambda uses the environment setting of the inspection (i.e. the environment specified on the inspections parent application entry) to obtain the role it needs. By assuming the role, <em>endpoint-check</em> Lambda is provided with temporary credentials to access the cross account by AWS STS. </p>

<h3>Authentication (Centralised SAML authentication)</h3>

<p>The Authentication Module has been developed as a Generalised self contained re-useable/shareable module, which is used by the <em>AMSDashboard</em> to provide Team member access via their Myer Azure AD signin credentials, and can be used by any future secured tool or secured application to provide Team Member access</p>

<p>The following diagram briefly describes the sub components involved and how the SAML authentication is achieved</p>

<p><img src="/images/SAML-Auth-Overview.jpg" alt="SAML Auth" /></p>

<p>For a better view of the diagram, and newer changes refer to this <a href="https://drive.google.com/open?id=1vlS2w8kW_d7EDXvu8kep02klMyQ2mMNw" target="_blank">link</a></p>

    </div>
)