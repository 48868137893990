import * as MyActions from './actions';
import * as Actions from '../dashboard/actions'
import * as ServiceActions from '../../services/actions';
import { _SYNC_ITEM_S3, COMMON_FIELDS } from '../dashboard/common'
import { apiException, findItemInList, changeMode, deleteItem, editItem, editItemContinue, editItemDiscard, cleanup, validateItem, copyItem, moveItem } from '../dashboard/reducers'
import { traverse, traverseApps, parentName, isRoot, isEmpty, relName, valueOf, level, objectId } from '../../util'
import Instruments from './instruments'

// Update state from root level to sublevel(s)
const updateIterate = (state, parent, inspections, inspectionState) => {
    
    var apps = [...state.apps] // clone
    var list = apps   
    const d = parent.name.split('.')
    var name = null
    var found = null
    // Clone the related parts of the structure - top down
    d.forEach ( (s, i) => { 
        name = name ? `${name}.${s}` : s
        found =  findItemInList(list, name, parent.env)
        list[found.index] = {...found.item} // clone 
        list[found.index].inspections = [...list[found.index].inspections] // clone
        if (i < d.length-1) {
            list = list[found.index].inspections
        }
    })
    list[found.index].inspections = inspections

    return {
        apps: apps,
        inspection: inspectionState,
    }
}

const updateRootDownState = (state, parent, inspections, save, keepOpen, level) => {

    // App changed - i.e. root level app/inspection state contains the sub level inspection states
    // This is the inspection open/save state --- not actual inspection instance
    const inspection = {...state.inspection} // Inspection/App state from root level 
    inspection.levels[level] = {
        ...inspection.levels[level],
        save: save,
        keepOpen: keepOpen
    }

    // The app's inspections have changed - TODO handle 3rd level
    const [...apps] = state.apps

    if (level === 0) {
        const found  = findItemInList(apps, parent)  // app and its index
        apps[found.index] = {...found.item}
        apps[found.index].inspections = inspections

        return {
            apps: apps,
            inspection: inspection,
        }
    } else {  // Handle lower levels
        return updateIterate(state, parent, inspections, inspection)
    }
}

const getLevel = (fqn) => level(fqn) - 1

const handlers = {
    [MyActions.CHANGE_INSP_MODE] : (state, action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]

            const env = action.payload.parent.env // should be same for child inspection
            const res = changeMode( state, action,      
                    traverse(state, action.payload.parent.name, env).inspections,    // Traverse to item to make sure we have latest state - Thunks may fire multiple actions which 
                                                                                     // May change state inbetween e.g. in editDiscard actions
                    traverse(state, action.payload.insp.name, env), 
                    inspectionsState.save,
                    inspectionsState.keepOpen,
            )

            return { ...state, ...updateRootDownState(
                    state,
                    action.payload.parent,
                    res.items,
                    res.save,
                    res.keepOpen,
                    level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.CHANGE_APP_MODE] : (state, action) => {

        // TODO for all sub levels 0 and 1
        // If lower level ignore - needs to be picked up by lower level
        const level = getLevel(action.payload.insp.name)   
        const inspectionsState = state.inspection.levels[level]
        if (inspectionsState.save && inspectionsState.save.changed && !isEmpty(inspectionsState.save.changed)) {
            const _items = [...inspectionsState.save.parent.inspections]
            const _save = {...inspectionsState.save, desired: action.payload.app, desiredLevel: 'ROOT'}  // Keep track of app we wanted to go to
            const changed = findItemInList(_items, inspectionsState.save.app);
            _items[changed.index] = changed.item  // A clone
            _items[changed.index].editState = Actions.EDIT_STATES.CANCEL  // Do we want to cancel previous apps changes which have not yet been saved
            return { ...state, ...updateRootDownState(
                state,
                inspectionsState.save.parent,
                _items,
                _save,
                { index: changed.index},
                level,
            )}
        }

        return state
    },
    [MyActions.MOVE_INSP] : (state, action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const res = moveItem( state, action, 
                [...action.payload.parent.inspections], 
                action.payload.insp, 
                inspectionsState.save,
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.ADD_GROUP] : (state, action) => {
        const [...insps] = action.payload.parent.inspections
        const name = `${action.payload.parent.name}.<New>`
        insps.push({ 
            name: name, 
            env:  action.payload.parent.env,
            type: 'group',  // Special type of instrument - groups inspections
            data: {},
            state: Actions.STATES.NEW,
            editState: Actions.EDIT_STATES.EDITED,
            mode: Actions.MODES.EDIT,
            new: true,
            inspections: [], // level 3
        })

        const level = getLevel(action.payload.parent.name) + 1   

        state.inspection.levels[level].save = state.inspection.levels[level].save || {}
        state.inspection.levels[level].save.changed = { [COMMON_FIELDS.NAME]: name }

        return { ...state, ...updateRootDownState(
            state,
            action.payload.parent,
            insps,
            state.inspection.levels[level].save,
            { index: insps.length-1},
            level,
        )}
    },
    [MyActions.ADD_INSP] : (state, action) => {
        const [...insps] = action.payload.parent.inspections
        const name = `${action.payload.parent.name}.<New>`
        insps.push({ 
            name: name, 
            env:  action.payload.parent.env,
            type: 'endpoint',  // TODO !!!!
            data: {},
            state: Actions.STATES.NEW,
            editState: Actions.EDIT_STATES.EDITED,
            mode: Actions.MODES.EDIT,
            new: true,
            inspections: [], // level 3
        })

        const level = getLevel(action.payload.parent.name) + 1  
        
        state.inspection.levels[level].save = state.inspection.levels[level].save || {}
        state.inspection.levels[level].save.changed = { [COMMON_FIELDS.NAME]: name }

        return { ...state, ...updateRootDownState(
            state,
            action.payload.parent,
            insps,
            state.inspection.levels[level].save,
            { index: insps.length-1},
            level,
        )}
    },
    [MyActions.COPY_INSP] : (state, action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const res = copyItem( state, action, 
                [...action.payload.parent.inspections], 
                action.payload.insp, 
                inspectionsState.save,
            )

            var copyName = `${action.payload.parent.name}.<Copy ${relName(action.payload.insp.name)}>`
            for (var i = 1; res.items.filter( (_item) => _item.name === copyName )[0]; i++) {
                copyName = `${action.payload.parent.name}.<Copy ${i} ${relName(action.payload.insp.name)}>`
            }
            res.items.push({...action.payload.insp,  
                name: copyName,   // TODO check if name exists and increment ... <Copy 1> 
                state: Actions.STATES.NEW,
                editState: Actions.EDIT_STATES.EDITED,
                mode: Actions.MODES.EDIT,
                new: true,
                inspections: [], // Make empty for now - if there are later - qualified name needs to be changed for all lower level insp's
            })

                // Need to set name field to save.changed to ensure name is validated i.e. <Copy ... is invalid
                const _save = { ...res.save,
                    changed: { [COMMON_FIELDS.NAME] : copyName}
                }

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                _save,
                { index: res.items.length-1},
                level,
            )}
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.DELETE_INSP] : (state, action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const res = deleteItem( state, action, 
                [...action.payload.parent.inspections], 
                action.payload.insp, 
                inspectionsState.save,
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}
        } catch (e) {
            console.log(e)
            return state;
        }

    },
    [MyActions.CONFIRM_DELETE_INSP] : (state, action) => {
        const level = getLevel(action.payload.insp.name)   
        const inspectionsState = state.inspection.levels[level]

        return { ...state, ...updateRootDownState(
            state,
            action.payload.parent,
            [...inspectionsState.save.confirm],
            null,
            null,
            level,
        )}
    },
    [MyActions.ABORT_DELETE_INSP] : (state, action) => {
        const level = getLevel(action.payload.insp.name)   
        const inspectionsState = state.inspection.levels[level]

        return { ...state, ...updateRootDownState(
            state,
            action.payload.parent,
            [...inspectionsState.save.abort],
            null,
            {...inspectionsState.keepOpen},
            level,
        )}
    },
    [MyActions.EDIT_INSP] : (state, action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const res = editItem( state, action, 
                [...action.payload.parent.inspections], 
                action.payload.insp, 
                inspectionsState.save,
            )
            res.save.parent = action.payload.parent

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.EDIT_INSP_CONTINUE] : (state,action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const res = editItemContinue( state, action, 
                [...action.payload.parent.inspections], 
                inspectionsState.save.app || action.payload.insp, 
                inspectionsState.save,
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.EDIT_INSP_DISCARD] : (state,action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const res = editItemDiscard( state, action, 
                [...action.payload.parent.inspections], 
                inspectionsState.save.app, 
                inspectionsState.save,
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.VALIDATE_INSP] : (state,action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]
            const type = valueOf("save.changed.type", inspectionsState) || action.payload.insp.type
            const validators = Instruments.validators[type] || {}
            const res = validateItem(state, action, 
                action.payload.parent.inspections, 
                action.payload.insp,
                validators.mandatory || [], // Mandatory
                validators, // validators
                inspectionsState.save
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.API_EXCEPTION_INSP] : (state,action) => {
        try {
            const level = getLevel(action.payload.insp.name)   
            const inspectionsState = state.inspection.levels[level]

            const res = apiException(state, action, 
                action.payload.parent.inspections, 
                action.payload.item,
                action.payload.fault,
                inspectionsState.save
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [MyActions.CLEANUP_INSP] : (state,action) => {
        try {
            const level = getLevel(action.payload.name)   
            //const inspectionsState = state.inspection.levels[level]
            const res = cleanup( state, action, 
                [...action.payload.parent.inspections], 
                action.payload.item, 
                //inspectionsState.save,
                action.payload.name,
            )

            return { ...state, ...updateRootDownState(
                state,
                action.payload.parent,
                res.items,
                res.save,
                res.keepOpen,
                level,
            )}

        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [ServiceActions.SYNC_DDB] : (state,action) => {
        const tbl = action.payload  // Will only contain non root items
        const apps = state.apps // HTH IBUG [...state.apps]
        // Keep track of updated parents - replace in tree at end
        const parents = {}
        const getParent = (FQN, env) => parents[FQN] && parents[FQN][env] ? parents[FQN][env] : null

        tbl.forEach(({item, env}) => {

            if (item.instrument === 'group') {
                debugger;
            }

            // TODO - what if parent not found i.e. does the lambda automatically create parent - it should! when an s3 event comes in without an existing parent application or parent node if 3rd or more level FQN 
            const parentFQN = parentName(item.FQN)
            var parent
            try {
                // HTH IBUG2 parent = /*parents[parentFQN]*/getParent(parentFQN, env) || {...traverse(state, parentFQN, env)}
                parent = getParent(parentFQN, env) || traverse(state, parentFQN, env)
            } catch (e) {
                //console.warn(`No parent found for ${item.FQN} Exception:${e}`)
                // likely a group 
                
                // Add the parent which should be a group type
                parent = {name: parentFQN, env: env, keep: true, inspections: [], type: 'group'}
                if (isRoot(parentFQN)) {
                    apps.push(parent)
                } else {
                    // Find parents parent
                    const pp = traverse(state, parentName(parentFQN), env)
                    pp.inspections.push(parent)
                }

            }
            var inspections = parent.inspections // HTH IBUG [...parent.inspections]
            const open = valueOf("filterMap.open.name",state) // Default accordian panel (inspection) to open
            const isDefault = open && open === item.FQN && valueOf("filterMap.open.inst", state) === item.instrument
            try {


                // traverse to items parent - the 
                const found = findItemInList(inspections,item.FQN, env)
                if (isDefault) {
                    parent.defaultActive = found.index  // Default active child
                }
                inspections[found.index] = objectId({ 
                    ...found.item, 
                    state: item.state !== undefined ? item.state : Actions.STATES.NEW,
                    metric : item.metric, 
                    env: item.Environment, 
                    timestamp: item.timestamp,
                    keep: true,
                }) 
            } catch (e) { // If not found - must be an app created by another GUI instance 
                          // or directly via s3 entry and/or DDB entry (via AWS console)
                if (isDefault) {
                    parent.defaultActive = inspections.length  // Default active child
                }
                inspections.push(objectId({
                    name: item.FQN, 
                    env: item.Environment, 
                    type: item.instrument, 
                    data: {},
                    state: item.state !== undefined ? item.state : Actions.STATES.NEW, 
                    metric : item.metric, 
                    editState: Actions.EDIT_STATES.START,
                    mode: Actions.MODES.VIEW,
                    inspections: [], // level 3
                    timestamp: item.timestamp,
                    keep: true,
                }))
            }

            parent.inspections = inspections     
            parents[parentFQN] = parents[parentFQN] || {}
            parents[parentFQN][env] = parent
        })
    
        // Prune out any items deemed to have been deleted/removed
        Object.keys(parents).forEach((pName) => {
            Object.keys(parents[pName]).forEach((env) => {
                const parent = parents[pName][env]

                // Prune inspections that are not marked with keep, or new (being created in this session), i.e. they must have been deleted, if no longer in the DDB table
                // Later when using DDB stream API - should get deletion notification and not need to do this pruning
                parent.inspections = parent.inspections.filter( insp => {
                    const keep = insp.keep || insp.new
                    insp.keep=false // reset for next SYNC_DDB
                    return keep
                })
                
                /* No longer necessary once cloning removed to fix IBug - also simplifies
                if (isRoot(parent)) {
                    try {
                        const found = findItemInList(apps,parent,env)
                        apps[found.index] = parent
                        //console.log(found)
                    } catch(e) {
                        console.error("Something went wrong: ", e)
                    }
                } else {
                    // Get parents parent inspections
                    try {
                        const pp = traverseApps(apps, parentName(pName), env)  // parents parent
                        objectId(pp)
                        pp.inspections = [...pp.inspections]
                        const found = findItemInList(pp.inspections,parent,env)
                        pp.inspections[found.index] = parent

                        *//*
                        console.log("found item at index: ",found.index)
                        console.log("parent inspections: ", parent.inspections, parent)
                        console.log("apps: ", apps)
                        console.log("pp is ", parentName(pName), pp)
                        console.log("pp object id: ",pp.__obj_id)
                        console.log("traverse to pp and get its inspections:", (traverseApps(apps, parentName(pName), env).inspections)[found.index].inspections)
                        const pp2 = traverseApps(apps, parentName(pName), env)  // parents parent
                        console.log("pp2 (same) object id: ",pp2.__obj_id)
                        *//* 
                    } catch(e) {
                        console.error("Something went wrong: ", e)
                    }
                }
                */
            })
        })
        
        return {...state, apps}
    },
    [_SYNC_ITEM_S3] : (state,action) => {
        // Pass to appropriate instrument
        return Instruments.reducers[action.payload.instrument](state, action)
    },
}

export default (state, action) => {
	
	if (action.type in handlers) {
		return handlers[action.type](state,action);
    }
    return state;
}


// Common sync S3 code
export const commonSyncS3 = (state, name, env, data, translate, instrument) => {
    // traverse to items parent
    const parent = {...traverse(state, parentName(name), env)}
    const inspections = [...parent.inspections]
    try {

        const found = findItemInList(inspections, name, env)
        //console.log(found)
        inspections[found.index] = { ...found.item, type: instrument, data: translate(data) } 
    } catch (e) { 
        // Must exist - something terribly wrong if not
        console.error("Something wrong: ", e)
    }
    // If parent is the root item - update apps --- if not its a 3rd level item, or lower 
    // and needs to be updated progressively upwards 
    parent.inspections = inspections
    const apps = [...state.apps]
    if (isRoot(parent)) {
        try {
            const found = findItemInList(apps,parent)
            apps[found.index] = parent
            //console.log(found)
        } catch(e) {
            console.error("Something wrong: ", e)
        }
    } else {
        // Get parents parent inspections
        try {
            const pp = traverseApps(apps, parentName(parent.name), env)  // parents parent
            pp.inspections = [...pp.inspections]
            const found = findItemInList(pp.inspections,parent,env)
            pp.inspections[found.index] = parent
        } catch(e) {
            console.error("Something went wrong: ", e)
        }
    }

    return { ...state, apps};
}