import React from 'react'
import CodeTag from 'components/basic/CodeTag'




export default () => (
    <div className='doc' style={{padding:'20px'}}>
<h2>EC2 Check Instrument</h2>

<p>The EC2 check instrument's structure is very similar to the 'endpoint' instrument. This instrument records the number of EC2 instances running each time it fires according to its schedule.</p>

<h3>Overview</h3>

<p>The following diagram, highlighted in the green part, briefly describes the components and interaction for this instrument.</p>

<p><img src="images/EC2-check-Overview.jpg" alt="EC2 Check Instrument" /></p>

<p>This instrument takes as arguments the number of bastions and the number of other EC2 instances that should be running. It will generate an SNS notification if the number of instances is below the number specified. It records the number of bastions running and terminate, and other EC2's running and terminated, this is displayed as separate graph (lines) in the graph display</p>

<p>Even though the structure of this instrument, is very similar to the 'endpoint' instrument, the common code between these instruments is captured in the layers. So for example the ec2-check-config really has very little code:</p>

<pre>
    <CodeTag code={String.raw`
            ...
            // Main 
            exports.handler = function(event, context, callback) {
                console.log('event =', JSON.stringify(event, null, 4));

                // Time triggered endpoint check - use cwConfig to create/delete the Cloudwatch event
                // Note handleS3Event upates the DDB table - if not using this method --- make sure to update the DDB 
                // table 
                // e.g. for create event (delete the DDB entry for a Delete event)
                //  if (event.Records[0].eventName === require('myer-support-dashboard/util).EVENTS.CREATED) {
                //      const tbl = myer_ddb.on(new AWS.DynamoDB())("AppSupportMonitor")
                //      tbl(myer_ddb.vargs("FQN",tuple.name)("Environment",tuple.env)).update(myer_ddb.vargs("timestamp",Date.now())("instrument",instrument)).then(...)
                //      ...
                cwConfig.handleS3Event(event, config.ec2Checker.arn, 'Ec2CheckLambda', 'ec2-check')
            };
        })`}/>
</pre>

<h3>View Mode</h3>

<p>In view mode a graph is displays a line for each of the following cases:
    1. # of running Bastion EC2s
    2. # of terminated  "
    3. # of running other EC2s
    4. $ of terminated  "</p>

<p>The graph data point, will be red, if a notification was issued. </p>

<blockquote>
  <p>Note: the graphs can be hidden/shown by clicking on the particular graph in the legend </p>
</blockquote>

<p><img src="images/ec2-check-graph.jpg" alt="EC2 Check Graph" /></p>

    </div>
)