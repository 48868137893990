import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const ModalBasicExample = (props) => (
  <Modal closeOnEscape={false} closeOnDimmerClick={false} dimmer='inverted' defaultOpen={true} size='small'>
    <Header icon={props.icon} content={props.hdr} />
    <Modal.Content>
      <p>
        {props.message}
      </p>
      {props.children}
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.actionNo} basic color='red' inverted>
        <Icon name='remove' /> No
      </Button>
      <Button onClick={props.actionYes} color='green' inverted>
        <Icon name='checkmark' /> Yes
      </Button>
    </Modal.Actions>
  </Modal>
)

export default ModalBasicExample