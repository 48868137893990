import * as DashboardActions from '../dashboard/actions'
import Instruments from './instruments'
import * as util from '../../util'
import * as Config from '../../config'
import * as Services from '../../services/services'

export const CHANGE_INSP_MODE = 'change_insp_mode'

export const ADD_INSP = 'add_insp'
export const COPY_INSP = 'copy_insp'
export const DELETE_INSP = 'delete_insp'

export const CONFIRM_DELETE_INSP = 'confirm_delete_insp'
export const ABORT_DELETE_INSP = 'abort_delete_insp'

export const EDIT_INSP = 'edit_insp'
export const EDIT_INSP_CONTINUE = 'edit_insp_continue'
export const EDIT_INSP_DISCARD = 'edit_insp_discard'

export const CLEANUP_INSP = 'cleanup_insp'

export const VALIDATE_INSP = 'validate_insp'
export const API_EXCEPTION_INSP = 'api_exception_insp'

export const ADD_GROUP = 'add_group'
export const MOVE_INSP = 'move_insp'

export const STOP_INSP = 'stop_insp'
export const RUN_INSP = 'run_insp'

// Declare more instruments here !!!!!
export const INSTRUMENTS = {
    ENDPOINT: 'ENDPOINT',
    RESTARTS: 'RESTARTS',
    // ... More
}


export const changeInspMode = (parent, insp, mode) => {
	/*
	return {
		type : CHANGE_INSP_MODE,
		payload : { parent: parent, insp: insp, mode: mode }
	} 
	*/

	return (dispatch, getState) => {
		// If app is in being edited, do validation flow
		const edited = 	insp.editState === DashboardActions.EDIT_STATES.EDITED || 
						insp.editState === DashboardActions.EDIT_STATES.FAILED 
		if (edited) {
			// Do validation dispatch
			dispatch({
				type : VALIDATE_INSP,
				payload : { parent: parent, insp: insp, mode: mode }
			})

			// Check if validation passed
			// application entry we want to navigate to
			const level = 0   // TODO  - see above - use qualified name to work out level
			const inspectionsState = getState().dashboard.inspection.levels[level]
			const save =  inspectionsState.save
			if (save && save.invalid !== null) {
				// Failed - exit
				return
			}
		} 
		
		dispatch({
			type : CHANGE_INSP_MODE,
			payload : { parent: parent, insp: insp, mode: mode }
		})
		
	}
}

export const addGroup = (parent) => {
	return {
		type : ADD_GROUP,
		payload : { parent: parent },
	}
}

export const moveInsp = (insp, parent, toParent) => {
	return {
		type : MOVE_INSP,
		payload : { insp: insp, parent: parent, toParent: toParent },
	}
}

export const confirmMoveInsp = (parent, insp) => {
	return (dispatch, getState) => {

		// get toParent from inspection state - save attributes
		const level = util.level(insp.name) - 1   // inspection state array sarts from 0 for level 1 onwards - root has its own save attribute on top level
		const inspectionsState = getState().dashboard.inspection.levels[level]
		const toParent = inspectionsState.save.moveTo

		const newName = `${toParent.name}.${util.relName(insp.name)}`
		dispatch(Instruments.actions[insp.type].save(parent, insp, true, newName, () =>
			dispatch(deleteInsp(parent, insp)) // Go through and prompt for deletion confirmation)
		))  

		dispatch(deleteInsp(parent, insp)) // Go through and prompt for deletion confirmation
	}

}

export const abortMoveInsp = (parent, insp, toParent) => {
	return {
		type : CLEANUP_INSP,
		payload: { parent: parent, item: insp, name: insp.name}
	}
}

export const addInsp = (parent) => {
	return {
		type : ADD_INSP,
		payload : { parent: parent },
	}
}

export const copyInsp = (parent, insp) => {
	return {
		type : COPY_INSP,
		payload : { parent: parent, insp: insp},
	}
}

// Parent could be app or lower level inspection - (depends on value of level)
export const deleteInsp = (parent, insp) => {
	return {
        type : DELETE_INSP,
        payload: { parent: parent, insp: insp}
	}
}

export const confirmDeleteInsp = (parent, insp) => {
	return Instruments.actions[insp.type].deleteInsp(parent, insp)
	/*
	return {
		type : CONFIRM_DELETE_INSP,
		payload: { parent: parent, insp: insp},
	}
	*/
}

export const abortDeleteInsp = (parent, insp) => {
	return {
		type : ABORT_DELETE_INSP,
		payload: { parent: parent, insp: insp}
	}
}

export const editInsp = (parent, insp, change) => {
	return {
		type : EDIT_INSP,
		payload : { parent: parent, insp: insp, change: change }
	}
}


export const editInspContinue = (parent, insp) => {
	return { 
		type: EDIT_INSP_CONTINUE,
		payload: { parent: parent, insp: insp},
	}
}


export const stopInsp = (parent, insp) => {
	return (dispatch, getState) => {
		// Handle within inspection action
		dispatch(Instruments.actions[insp.type].stopInsp(parent, insp))
	}
}

export const runInsp = (parent, insp) => {
	return (dispatch, getState) => {
		// Handle within inspection action
		dispatch(Instruments.actions[insp.type].runInsp(parent, insp))
	}
}


const toggle = (mode) => mode === DashboardActions.MODES.EDIT ? DashboardActions.MODES.VIEW : DashboardActions.MODES.EDIT

// Use thunk to fire 2 actions sequentially
export const editInspDiscard = (parent) => {
	return (dispatch, getState) => {
		// application entry we want to navigate to
		const level = 0   // TODO  - see above - use qualified name to work out level
		const inspectionsState = getState().dashboard.inspection.levels[level]

		const desired = inspectionsState.save.desired
		const desiredLevel = inspectionsState.save.desiredLevel
		const current = inspectionsState.save.app

		// Dispatch discard
		dispatch({ 
			type: EDIT_INSP_DISCARD, 
			payload : { parent: parent},
		})

		// View/Edit current app
		dispatch({
			type : CHANGE_INSP_MODE,
			payload : { parent: parent, insp: current, mode: toggle(current.mode) }
		})

		// View/Edit desired
		if (desiredLevel === 'ROOT') {
			dispatch({
				type : DashboardActions.CHANGE_APP_MODE,
				payload : { app: desired, mode: toggle(desired.mode) }
			})
		} else {
			dispatch({
				type : CHANGE_INSP_MODE,
				payload : { parent: parent, insp: desired, mode: toggle(desired.mode) }
			})
		}

	}
}

export const save = (parent, insp) => {
	return (dispatch, getState) => {
		// Dispatch rest call - which adds to s3 or updates s3
		const level = util.level(insp.name)

		const state = getState().dashboard
		const changed = (level === 0 ? state.save.changed : state.inspection.levels[level-1].save.changed) || {}
		const type = changed.type || insp.type 
		dispatch(Instruments.actions[type].save(parent, insp))
	}
}


// Generic S3 save/delete actions
export const saveInspS3 = (parent, insp, bodyFn, additionalParams) => {

	return (dispatch, getState) => {

		dispatch({
			type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
		})

		// Do asynchronously
		const tout = setTimeout(() => {

            // Dispatch rest call - which adds to s3 or updates s3
            const level = util.level(insp.name)

			const state = getState().dashboard
			//const changed = level === 0 ? state.save.changed : state.inspection.levels[level-1].save.changed 
			const save = level === 0 ? state.save : state.inspection.levels[level-1].save
			var changed = {}     
			if (save && save.changed) { // changed should only be empty if there is a move, otherwise should always get in here 
				changed = save.changed
			}

            // Merge what's changed with the existing data
            const raw = { ...insp.data, ...changed}
            
            // Assume for now parent is app, if level is 2 the have to find app
            // Using first component of FQN
            const app = parent

            // If name has changed - we need to change the name 
            // TODO - at the moment changing name on root level is not supported - need functionality that goes
            // and changes the name on all children and updates the s3 file names (deletes old and creates new ones) 
            // Name must be the fully qualified name
            const name = changed.name !== undefined ? `${parent.name}.${changed.name}` : insp.name

            // S3 object name - encodes fullname and env
            const s3name = `${name.trim().replace(/ /g,'__')}..${app.env}`

            // TODO add signed request

            // Fire rest call to APIGateway
            var pathParams = {
                //This is where path request params go. 
            };
            // Template syntax follows url-template https://www.npmjs.com/package/url-template
            var pathTemplate = `/${Config.API_STAGE}/s3-manage`
            var method = 'POST';

			const body = bodyFn(s3name, raw, app)
            
            Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
                .then(function(result){
                    // Changes committed to backend - do cleanup - the changes should be recieved from the backend 
					dispatch({
						type : CLEANUP_INSP,
						payload : { parent, item: insp, name }  // name will be different, if this is a new inspection --- cleanup needs to handle
					})
                    // Dispatch change mode
					dispatch({
						type : CHANGE_INSP_MODE,
						payload : { parent, insp: insp, mode: DashboardActions.MODES.VIEW }
					})
                }).catch( function(result){
                    console.log(`Failed API Call: ${result}`)
                    dispatch({
                        type : API_EXCEPTION_INSP,
                        payload : { fault: result, item: insp, parent: parent }
                    })
                });
      

            clearTimeout(tout)	
        }, 0)

	}
}


export const deleteInspS3 = (parent, insp, bodyFn, additionalParams) => {

   return (dispatch, getState) => {

        dispatch({
            type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
        })

        // Do asynchronously
        const tout = setTimeout(() => {

            // Dispatch rest call - which adds to s3 or updates s3
            //const level = util.level(insp.name)

            //const state = getState().dashboard
            //const changed = level === 0 ? state.save.changed : state.inspection.levels[level-1].save.changed 

            
            // Assume for now parent is app, if level is 2 the have to find app
            // Using first component of FQN
            const app = parent

            // If name has changed - we need to change the name 
            // TODO - at the moment changing name on root level is not supported - need functionality that goes
            // and changes the name on all children and updates the s3 file names (deletes old and creates new ones) 
			const name = insp.name
			
			// S3 object name - encodes fullname and env
			const s3name = `${name.trim().replace(/ /g,'__')}..${app.env}`

            // Construct REST call body
			const body = bodyFn(s3name, app)

            // Fire rest call to APIGateway
            var pathParams = {
                //This is where path request params go. 
            };
            // Template syntax follows url-template https://www.npmjs.com/package/url-template
            var pathTemplate = `/${Config.API_STAGE}/s3-manage`
            var method = 'DELETE';

            
            Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
                .then(function(result){
                    // Changes committed to backend - do cleanup - the changes should be recieved from the backend 
                    dispatch({
                        type : CONFIRM_DELETE_INSP,
                        payload: { parent: parent, insp: insp},
                    })
                }).catch( function(result){
                    console.log(`Failed API Call: ${result}`)
                    dispatch({
                        type : API_EXCEPTION_INSP,
                        payload : { fault: result, item: insp, parent: parent }
                    })
                });

            clearTimeout(tout)	
        }, 0)
    }
}

export const moveInspS3 = (parent, insp, bodyFn, newName, additionalParams, compFn) => {

	return (dispatch, getState) => {

		dispatch({
			type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
		})

		// Do asynchronously
		const tout = setTimeout(() => {
            
            // Assume for now parent is app, if level is 2 the have to find app
            // Using first component of FQN
            const app = parent

            // S3 object name - encodes fullname and env
            const s3name = `${newName.trim().replace(/ /g,'__')}..${app.env}`

            // Fire rest call to APIGateway
            var pathParams = {
                //This is where path request params go. 
            };
            // Template syntax follows url-template https://www.npmjs.com/package/url-template
            var pathTemplate = `/${Config.API_STAGE}/s3-manage`
            var method = 'POST';

			const body = bodyFn(s3name, insp.data, app)
            
            Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
                .then(function(result){
					// Cleanup and Delete the inspection
					// Changes committed to backend - do cleanup - the changes should be recieved from the backend 
					// Manually delete old after moving
					dispatch({
						type : CLEANUP_INSP,
						payload : { parent, item: insp, name: insp.name }  // name will be different, if this is a new inspection --- cleanup needs to handle
					})

					if (compFn != null) {
						compFn()
					}
					/*
					// Dispatch change mode
					dispatch({
						type : CHANGE_INSP_MODE,
						payload : { parent, insp: insp, mode: DashboardActions.MODES.VIEW }
					})
					dispatch(deleteInspS3(parent, insp, (s3name, raw, app) => {
						return {
							meta: {
								name : s3name,
								instrument : insp.type // This instrument
							}
						}}, {}))
					*/
                }).catch( function(result){
                    console.log(`Failed API Call: ${result}`)
                    dispatch({
                        type : API_EXCEPTION_INSP,
                        payload : { fault: result, item: insp, parent: parent }
                    })
                });
      

            clearTimeout(tout)	
        }, 0)

	}
}


export const runStopInspS3 = (state, parent, insp, bodyFn, additionalParams, compFn) => {
	return (dispatch, getState) => {

		dispatch({
			type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
		})
            
		//
		const app = util.traverse(getState().dashboard,util.root(insp.name),parent.env)

		// S3 object name - encodes fullname and env
		const s3name = `${insp.name.trim().replace(/ /g,'__')}..${app.env}`

		// Fire rest call to APIGateway
		var pathParams = {
			//This is where path request params go. 
		};
		// Template syntax follows url-template https://www.npmjs.com/package/url-template
		var pathTemplate = `/${Config.API_STAGE}/s3-manage`
		var method = 'POST';

		const body = bodyFn(s3name, insp.data, app)
		body.data.state = state
		
		Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
			.then(function(result){
				// Cleanup and Delete the inspection
				// Changes committed to backend - do cleanup - the changes should be recieved from the backend 
				// Manually delete old after moving
				dispatch({
					type : CLEANUP_INSP,
					payload : { parent, item: insp, name: insp.name }  // name will be different, if this is a new inspection --- cleanup needs to handle
				})

				if (compFn != null) {
					compFn()
				}
			}).catch( function(result){
				console.log(`Failed API Call: ${result}`)
				dispatch({
					type : API_EXCEPTION_INSP,
					payload : { fault: result, item: insp, parent: parent }
				})
			});

	}
}


export const runInspS3 = (parent, insp, bodyFn, additionalParams, compFn) => {

	return runStopInspS3('ENABLED', parent, insp, bodyFn, additionalParams, compFn)
}

export const stopInspS3 = (parent, insp, bodyFn, additionalParams, compFn) => {

	return runStopInspS3('DISABLED', parent, insp, bodyFn, additionalParams, compFn)
}