import React from 'react'
import { Segment, Form, Checkbox } from 'semantic-ui-react'
import { FormInput, FormTextArea, FormGroup} from '../../basic/input/form'
import * as Actions from './actions'
import * as Util from '../../../util'
import { error } from '../../dashboard/common'
import ScheduleForm from '../scheduleForm'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
    };
    const { app, item, actions, dashboard} = props   // item is app - if root level, else inspection instance
    const data = item.data || {}
    // Merge with any data changed so far
    const level = Util.level(item.name)
    const save = dashboard.inspection.levels[level-1].save
    const merged = save ? { ...data, ...save.changed } : data
    const parent = Util.traverse(dashboard, Util.parentName(item.name), app.env)
    const set = (field, value) => actions.editInsp(parent, item, { att: field, value: value})  
    return (
        <div style = {{...styles}}>
           <ScheduleForm dashboard={dashboard} merged={merged} level={level} set={set} fields={Actions.FIELDS} />
            <Segment color='blue'>
                <Form>
                    <FormTextArea {...error(dashboard,Actions.FIELDS.QUEUES, level)} style={{}} label='Queues' placeholder='Array of SQS queues (seperated by a comma, no spaces between)... e.g. https://sqs.ap-southeast-2.amazonaws.com/712164089289/myticketing-test-ap-southeast-2-myer-dlq,https://sqs.....' value={merged[Actions.FIELDS.QUEUES]} onChange={(e, { value }) => set(Actions.FIELDS.QUEUES, value)}/>
                </Form>   
            </Segment>
            <Segment color='yellow'>
                <label>Tolerance</label>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormInput {...error(dashboard,Actions.FIELDS.TOLERANCE, level)} type='number' label='Number' placeholder='Queue depth to trigger alert' value={merged[Actions.FIELDS.TOLERANCE]} onChange={(e, { value }) => set(Actions.FIELDS.TOLERANCE, value)}n/>
                    </Form.Group>
                </Form>   
            </Segment>
        </div>
    )
})