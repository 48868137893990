import React from 'react'

import { COMMON_FIELDS, GENERAL } from '../../dashboard/common'

import ScheduleDoc from './schedule'
import StateDoc from './state'
import { INSTRUMENTS} from '../instruments'

const instMainDoc = () => {
    const instDocs = {}
    INSTRUMENTS.forEach( (inst) => {
        try {
            const Component = require(`../${inst}/doc/main`).default
            instDocs[inst] = (<Component />)
        } catch (err) {
            console.warn("No main Info Document found for instrument: ", inst)
        }
    })
    return instDocs
}

export default {
    [GENERAL] : {
        ...instMainDoc(),
        /** 
        'endpoint' : 'TODO ... documentation of endpoint instrument',
        'restarts' : 'TODO ... documentation of restarts instrument',
        'ec2-check' : 'TODO ... documentation of ec2-check instrument',
        'ec2-status-change' : 'TODO ... documentation of ec2-status-change instrument',
        */
        [COMMON_FIELDS.SCHEDULE] : (<ScheduleDoc/>), //(<p>This is help info for Schedule .....</p>)
        [COMMON_FIELDS.STATE] : (<StateDoc/>),
    }
}



