import {nameValidator, scheduleValidator, jsonValidator, COMMON_FIELDS} from '../../dashboard/common'
import {FIELDS} from './actions'


export default {
    mandatory: [COMMON_FIELDS.NAME, COMMON_FIELDS.SCHEDULE, FIELDS.STATE, FIELDS.HOST, FIELDS.PATH, FIELDS.METHOD, FIELDS.SCHEME],// FIELDS.METHOD, FIELDS.PATH, FIELDS.SCHEME]
    [COMMON_FIELDS.NAME] : nameValidator,
    [COMMON_FIELDS.SCHEDULE] : scheduleValidator,
    [FIELDS.HEADERS] : jsonValidator,
    [FIELDS.BODY] : jsonValidator,
    [FIELDS.EXPECTED] : jsonValidator,
}