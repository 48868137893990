/**
 * 
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import configureStore from './store';

import './index.css';
import App from './App';
import { register } from './serviceWorker';

import 'semantic-ui-css/semantic.min.css';

import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

ReactDOM.render(
<DndProvider backend={HTML5Backend}>
  <Provider store={configureStore()}>
      <Router>
        <Route path="/:filter?" component={App} />
      </Router>
  </Provider>
 </DndProvider> ,
 document.getElementById('root')
);
register();
