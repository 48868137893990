import React from 'react'
import { Line } from 'react-chartjs-2';
import GraphConfigView from '../graphConfigView'

import * as util from '../../../util'

const transform = (data) => {
    return data.reduce((acc,val) => {
        acc.labels.push(new Date(val.timestamp).toLocaleString())
        acc.datasets[0].data.push(val.measurement.responseTime)
        return acc
    }, {
        labels: [],
        datasets: [{
              label: 'Response Time', 
              data: [],
              fill: false,          // Don't fill area under the line
              borderColor: 'blue',  // Line color
              borderWidth: 1,
              pointBackgroundColor: (context) => {
                try {      
                    return util.MapToColour[data[context.dataIndex].measurement.status]
                } catch (err) {
                    console.warn("Could not properly process metric data")
                    return 'black'  
                }
              }
        }]
    })
}


/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
        padding: '20px'
    };
    const { item, /*app, item, actions*/} = props   // item is app - if root level, else inspection instance
    const metric = item.metric ? JSON.parse(item.metric) : null
    const gData = metric ? transform(metric) : null
    // Segment changes to colour of last metric - in sequence
    const sColour = gData ? util.MapToColour[metric[metric.length - 1].measurement.status] : 'blue'

   return (
    <GraphConfigView item={item} sColour={sColour} label="EndPoint Check Instrument" graphLabel="Response Time" hasGraph={gData !== null}>
        <Line data={gData}/>
    </GraphConfigView>
  ) 
});