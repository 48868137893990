import React from 'react'
import { Segment, Form, Checkbox } from 'semantic-ui-react'
import { FormInput, FormTextArea, FormGroup, FormSelect,LabelInfo} from '../../basic/input/form'
import * as Actions from './actions'
import * as Util from '../../../util'
import { error, info } from '../../dashboard/common'
import ScheduleForm from '../scheduleForm'


/* Stateless functional component - make class if require more advanced behaviour*/
/*
export default (props => {
    const styles = { 
    };
    const { app, item, actions, dashboard} = props   // item is app - if root level, else inspection instance
    const methodOptions = [
        { key: 'get', text: 'get', value: 'GET' },
        { key: 'post', text: 'post', value: 'POST' },
        { key: 'head', text: 'head', value: 'HEAD' },
      ]
    //const fields = {}
    const data = item.data || {}
    // Merge with any data changed so far
    const level = Util.level(item.name)
    const save = dashboard.inspection.levels[level-1].save
    const merged = save ? { ...data, ...save.changed } : data
    const set = (field, value) => actions.editInsp(app, item, { att: field, value: value})  
    return (
        <div style = {{...styles}}>
            <ScheduleForm dashboard={dashboard} merged={merged} level={level} set={set} fields={Actions.FIELDS} />
            <Segment color='blue'>
                <Form>
                    <Form.Group style={{paddingTop:'20px', alignItems: 'flex-start'}} inline >
                        <Segment.Group style={{border:'none', boxShadow:'none', width:'80%'}}>
                            <FormSelect {...error(dashboard,Actions.FIELDS.METHOD, level)} inline label='Method' options={methodOptions} placeholder='Http Method' value={merged[Actions.FIELDS.METHOD]} onChange={(e, { value }) => set(Actions.FIELDS.METHOD, value)}/>
                            <FormInput cn2='sixteen wide' {...error(dashboard,Actions.FIELDS.HOST, level)} width={16} inline style={{padding:'20px', paddingRight:'0'}} label='Host' placeholder='Host' value={merged[Actions.FIELDS.HOST]} onChange={(e, { value }) => set(Actions.FIELDS.HOST, value)}/>
                            <FormInput cn2='sixteen wide'  {...error(dashboard,Actions.FIELDS.PATH, level)} width={16} inline style={{paddingLeft:'20px'}} label='Path' placeholder='Path' value={merged[Actions.FIELDS.PATH]} onChange={(e, { value }) => set(Actions.FIELDS.PATH, value)}/>
                        </Segment.Group>
                        <Segment.Group style={{border:'none', boxShadow:'none', width:'80%'}}>
                            <Form.Group {...info(Actions.INSTRUMENT, Actions.FIELDS.SAVE_RESPONSE)} {...error(dashboard,Actions.FIELDS.SAVE_RESPONSE, level)} style={{float: 'right', padding: '0 1em 0 0'}} >
                                <label style={{paddingRight: '20px'}} >Save Response</label>
                                <Form.Radio label='Yes' value='YES' checked={merged[Actions.FIELDS.SAVE_RESPONSE] === 'YES'} onChange={(e, { value }) => set(Actions.FIELDS.SAVE_RESPONSE, value)}  />
                                <Form.Radio label='No' value='NO' checked={merged[Actions.FIELDS.SAVE_RESPONSE] === 'NO'} onChange={(e, { value }) => set(Actions.FIELDS.SAVE_RESPONSE, value)}  />
                            </Form.Group>
                            <FormGroup {...error(dashboard,Actions.FIELDS.SCHEME, level)} style={{float: 'right', padding: '0 50px 0 0'}} >
                                <label style={{paddingRight: '20px'}} >Scheme</label>
                                <Form.Radio label='http' value='http' checked={merged[Actions.FIELDS.SCHEME] === 'http'} onChange={(e, { value }) => set(Actions.FIELDS.SCHEME, value)}  />
                                <Form.Radio label='https' value='https' checked={merged[Actions.FIELDS.SCHEME] === 'https'} onChange={(e, { value }) => set(Actions.FIELDS.SCHEME, value)}  />
                            </FormGroup>
                            <FormTextArea {...info(Actions.INSTRUMENT, Actions.FIELDS.HEADERS)} {...error(dashboard,Actions.FIELDS.HEADERS, level)} style={{}} label='Headers' placeholder='Http Headers... e.g. { Content-type:application/json,... }' value={Util.ppJson(merged[Actions.FIELDS.HEADERS])} onChange={(e, { value }) => set(Actions.FIELDS.HEADERS, value)}/>
                        </Segment.Group>
                    </Form.Group> 
                </Form>   
            </Segment>
            <Segment color='red'>
                <Form>
                    <Form.Field>
                        <Checkbox label='AWS Signed Request' checked={merged[Actions.FIELDS.SIGNED] === true} onChange={(e, { value }) => set(Actions.FIELDS.SIGNED, !merged[Actions.FIELDS.SIGNED])}  />           
                    </Form.Field>
                    { merged[Actions.FIELDS.SIGNED] ?
                        <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                            <FormInput cn1='field' inline style={{}} label='Region' placeholder='e.g. ap-southeast-2' value={merged[Actions.FIELDS.REGION]} onChange={(e, { value }) => set(Actions.FIELDS.REGION, value)} />
                        </Form.Group> : null
                    }
                </Form>
            </Segment>
            <Segment color='green'>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormTextArea cn1='field' {...error(dashboard,Actions.FIELDS.BODY, level)} inline style={{}} label='Body' placeholder='e.g. { "searchTerm": "Wednesday", "limit": 25, "userPrincipleName": "htaylor" }' value={Util.ppJson(merged[Actions.FIELDS.BODY])} onChange={(e, { value }) => set(Actions.FIELDS.BODY, value)} />          
                        <FormTextArea cn1='field' {...error(dashboard,Actions.FIELDS.EXPECTED, level)} inline style={{}} label='Expected' placeholder='e.g. { "httpStatus": 200  }' value={Util.ppJson(merged[Actions.FIELDS.EXPECTED])} onChange={(e, { value }) => set(Actions.FIELDS.EXPECTED, value)} />
                    </Form.Group>
                </Form>
            </Segment>
        </div>
    )
})
*/

export default (props => {
    const styles = { 
    };
    const { app, item, actions, dashboard} = props   // item is app - if root level, else inspection instance
    const methodOptions = [
        { key: 'get', text: 'get', value: 'GET' },
        { key: 'post', text: 'post', value: 'POST' },
        { key: 'head', text: 'head', value: 'HEAD' },
      ]
    //const fields = {}
    const data = item.data || {}
    // Merge with any data changed so far
    const level = Util.level(item.name)
    const save = dashboard.inspection.levels[level-1].save
    const merged = save ? { ...data, ...save.changed } : data
    const parent = Util.traverse(dashboard, Util.parentName(item.name), app.env)
    const set = (field, value) => actions.editInsp(parent, item, { att: field, value: value})  
    return (
        <div style = {{...styles}}>
            <ScheduleForm dashboard={dashboard} merged={merged} level={level} set={set} fields={Actions.FIELDS} />
            <Segment color='blue'>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal' >
                        <FormSelect style={{marginRight: '50px'}} {...error(dashboard,Actions.FIELDS.METHOD, level)} inline label='Method' options={methodOptions} placeholder='Http Method' value={merged[Actions.FIELDS.METHOD]} onChange={(e, { value }) => set(Actions.FIELDS.METHOD, value)}/>
                        <FormGroup style={{paddingRight: '50px'}} {...info(Actions.INSTRUMENT, Actions.FIELDS.SAVE_RESPONSE)} {...error(dashboard,Actions.FIELDS.SAVE_RESPONSE, level)}  >
                            <label style={{paddingRight: '20px'}} >Save Response</label>
                            <Form.Radio label='Yes' value='YES' checked={merged[Actions.FIELDS.SAVE_RESPONSE] === 'YES'} onChange={(e, { value }) => set(Actions.FIELDS.SAVE_RESPONSE, value)}  />
                            <Form.Radio label='No' value='NO' checked={merged[Actions.FIELDS.SAVE_RESPONSE] === 'NO'} onChange={(e, { value }) => set(Actions.FIELDS.SAVE_RESPONSE, value)}  />
                        </FormGroup>
                        <FormGroup {...error(dashboard,Actions.FIELDS.SCHEME, level)}  >
                            <label style={{paddingRight: '20px'}} >Scheme</label>
                            <Form.Radio label='http' value='http' checked={merged[Actions.FIELDS.SCHEME] === 'http'} onChange={(e, { value }) => set(Actions.FIELDS.SCHEME, value)}  />
                            <Form.Radio label='https' value='https' checked={merged[Actions.FIELDS.SCHEME] === 'https'} onChange={(e, { value }) => set(Actions.FIELDS.SCHEME, value)}  />
                        </FormGroup>
                    </Form.Group>
                    <Form.Group inline >
                        <Segment.Group style={{margin:0, border:'none', boxShadow:'none', width:'80%'}}>
                            <FormInput style={{paddingRight:'0'}} cn2='sixteen wide' {...error(dashboard,Actions.FIELDS.HOST, level)} width={16} inline  label='Host' placeholder='Host' value={merged[Actions.FIELDS.HOST]} onChange={(e, { value }) => set(Actions.FIELDS.HOST, value)}/>
                            <FormInput style={{paddingTop: '20px'}} cn2='sixteen wide'  {...error(dashboard,Actions.FIELDS.PATH, level)} width={16} inline  label='Path' placeholder='Path' value={merged[Actions.FIELDS.PATH]} onChange={(e, { value }) => set(Actions.FIELDS.PATH, value)}/>
                        </Segment.Group>
                        <Segment.Group style={{margin:0, border:'none', boxShadow:'none', width:'80%'}}>
                            <FormTextArea {...info(Actions.INSTRUMENT, Actions.FIELDS.HEADERS)} {...error(dashboard,Actions.FIELDS.HEADERS, level)} style={{}} label='Headers' placeholder='Http Headers... e.g. { Content-type:application/json,... }' value={Util.ppJson(merged[Actions.FIELDS.HEADERS])} onChange={(e, { value }) => set(Actions.FIELDS.HEADERS, value)}/>
                        </Segment.Group>
                    </Form.Group> 
                </Form>   
            </Segment>
            <Segment color='red'>
                <Form>
                    <Form.Field>
                        <Checkbox label='AWS Signed Request' checked={merged[Actions.FIELDS.SIGNED] === true} onChange={(e, { value }) => set(Actions.FIELDS.SIGNED, !merged[Actions.FIELDS.SIGNED])}  />           
                    </Form.Field>
                    { merged[Actions.FIELDS.SIGNED] ?
                        <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                            <FormInput cn1='field' inline style={{}} label='Region' placeholder='e.g. ap-southeast-2' value={merged[Actions.FIELDS.REGION]} onChange={(e, { value }) => set(Actions.FIELDS.REGION, value)} />
                        </Form.Group> : null
                    }
                </Form>
            </Segment>
            <Segment color='green'>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormTextArea cn1='field' {...error(dashboard,Actions.FIELDS.BODY, level)} inline style={{}} label='Body' placeholder='e.g. { "searchTerm": "Wednesday", "limit": 25, "userPrincipleName": "htaylor" }' value={Util.ppJson(merged[Actions.FIELDS.BODY])} onChange={(e, { value }) => set(Actions.FIELDS.BODY, value)} />          
                        <FormTextArea cn1='field' {...error(dashboard,Actions.FIELDS.EXPECTED, level)} inline style={{}} label='Expected' placeholder='e.g. { "httpStatus": 200  }' value={Util.ppJson(merged[Actions.FIELDS.EXPECTED])} onChange={(e, { value }) => set(Actions.FIELDS.EXPECTED, value)} />
                    </Form.Group>
                </Form>
            </Segment>
        </div>
    )
})