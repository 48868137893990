
/*
// Returns if a value is an object
export const isObject = (value) => {
    return value && typeof value === 'object' && value.constructor === Object;
}
*/

// Prettty print JSON and check if object 
export const ppJson = (json) => {
    return isObject(json) ?
        JSON.stringify(json,null,2) : json
}


export const getConditionalProps = (errorMssg) => {
    const props = {};
    // fill your `invalid` variable in this function or pass it to it
    if (errorMssg !== undefined) {
        props.error = true;
    }
    return props
  }


// Returns cloned found app i.e. a copy
export const findItemInList = (list, item, env) => {
    var _index = -1;
    const name = isObject(item) ? item.name : item
    const _env = env || item.env
    if (_env === undefined) {
        throw new Error("findItemInList::Environment not specified. Cannot uniquely identify an item in the list")
    }
    const found = list.filter( (_item, index) => {
        const comp = _item.name === name && _item.env === _env
        if (comp) {
            _index= index // Found index
        }
        return comp    
    })[0]
    if (!found) {
        throw new Error(`findItemInList::Item ${name} Not Found!`)
    }
    return {
        item: {...found},
        index : _index
    }
}


const _traverse = (stack, list, env, parentName = '') => {
    const name = stack.pop()
    const _name = (parentName === '' ? name : `${parentName}.${name}`)
    const item = list.filter( (_item, index) => {
        //const _name = (parentName === '' ? name : `${parentName}.${name}`)
        return _item.name === _name && _item.env === env                       // Name and env makes it unique/same - especially on root level
    })[0]
    if (item === undefined) {
        throw new Error("_traverse::Item Not Found")
    }
    return (stack.length > 0) ? _traverse(stack, item.inspections, env, _name) : item
}

// traverse to item in hierarchy - step down hierarchy using qualified name to get item
export const traverse = (state, name, env) => {
    return _traverse(name.split('.').reverse(), state.apps, env)
} 

export const traverseApps = (apps, name, env) => {
    return _traverse(name.split('.').reverse(), apps, env)
} 

// Get relative (last component) name from qualified name
export const relName = (name) => name.split('.').pop()

// Get level using FQN - 0 (ROOT LEVEL), 1 or 2
export const level = (name) => name.split('.').length - 1

// Lop relative name off i.e. get parent qualified name
// returns empty string if a root name is passed in e.g. "Myerone", 
// a non root name is something like "Myerone.Test..." 
export const parentName = (name) => {
    var split = name.split('.');
    split.pop()
    return split.join('.')
}

// Get root parent name, given FQN
export const root = (name) => {
    return name.split('.').reverse().pop();
}

// Is this a root item/name
export const isRoot = (item) => {
    const name = isObject(item) ? item.name || item.FQN : item
    return name.lastIndexOf('.') === -1     // If empty it is a root
}

// Is variable a primitive or an object
export const isObject = (arg) => arg === Object(arg)


// Find the item in a given list - both name and environment must match
export const find = (list, item, env) => {
    const name = isObject(item) ? item.name || item.FQN : item
    const found = list.filter( (_item) => (_item.name === name || _item.FQN === name) && (_item.env === env || _item.Environment === env))[0]
    if (!found) {
        throw new Error("Item Not Found!")
    }
    return found
}

export const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false
    }
    return true
}

export const isString = _var => typeof _var === 'string' || _var instanceof String

// TODO should be in NPM common to front end and back end
export const STATES = {
    GREEN:  1,
    AMBER:  2,
    RED:    3,
    NEW:    4,
}

export const MapToColour = {
    [STATES.GREEN] : 'green',
    [STATES.AMBER] : 'orange',
    [STATES.RED] : 'red',
    [STATES.NEW] : 'grey',
}


export const valueOf = (field, object) => {
    const res = field.split('.').reduce((acc,val,ind,arr) => { 
//console.log(`acc ${acc} val ${val}`)
        if (acc.found) {
            return acc
        }
        if (acc.val === undefined) {
            return { val: undefined, found: false }
        } else {
            var ret = acc.val[val];
            if (ret === undefined && (ind+1)<arr.length) { // Check case for flattened structure
                ret = acc.val[arr.slice(ind,arr.length).join('.')]
                acc.found = ret !== undefined
            }
            return {...acc, val: ret};
        } 
    }, { val: object, found: false})
    return res.val;
  }  


var __next_objid=1;
export const objectId = (obj) => {
    if (obj==null) return null;
    if (obj.__obj_id==null) obj.__obj_id=__next_objid++;
    return obj;
}


export const isActive = (item) => {
    if (item.type !== 'group' && item.data) {
        return item.data.state === 'ENABLED'
    } 
    // else handle group etc
}
