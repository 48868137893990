import React from 'react'

import * as Actions from '../actions' 



export default {
    [Actions.INSTRUMENT] : {
        // ... Fields
    }
}