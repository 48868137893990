import * as MyActions from './actions'
import { _SYNC_ITEM_S3 } from '../../dashboard/common'
import { commonSyncS3 } from '../commonInst'

/*
		// translate
		const body = {
			"name" : `${item.name.trim().replace(/ /g,'__')}..${app.env}`,
			"description": "TODO .....",
			"scheduleExpression" : raw[FIELDS.SCHEDULE],
			"state" : raw[FIELDS.STATE],
			"application" : app.name,  
			"endpoint": {   
				"endpoint": {     
					"options": {       
						"method": raw[FIELDS.METHOD], 
						"host": raw[FIELDS.HOST],       
						"path": raw[FIELDS.PATH],       
						"headers": JSON.parse(raw[FIELDS.HEADERS]),
					},     
                    "scheme": raw[FIELDS.SCHEME],
                    "data": raw[FIELDS.BODY],
				},   
				"expected": JSON.parse(raw[FIELDS.EXPECTED])
			}
        }
*/

const translate = (data) => { return {
    [MyActions.FIELDS.DESCRIPTION] : data.description,
    [MyActions.FIELDS.SCHEDULE] : data.scheduleExpression,
    [MyActions.FIELDS.STATE] : data.state,   // This state is whether enabled or disabled - not the endpoint running state
    [MyActions.FIELDS.METHOD] : data.endpoint.endpoint.options.method,
    [MyActions.FIELDS.HOST] : data.endpoint.endpoint.options.host,
    [MyActions.FIELDS.PATH] : data.endpoint.endpoint.options.path,
    [MyActions.FIELDS.HEADERS] : data.endpoint.endpoint.options.headers,
    [MyActions.FIELDS.SAVE_RESPONSE] : data.endpoint.endpoint.saveResponse,
    [MyActions.FIELDS.SCHEME] : data.endpoint.endpoint.scheme,
    [MyActions.FIELDS.BODY] : data.endpoint.endpoint.data,
    [MyActions.FIELDS.EXPECTED] : data.endpoint.expected,
    [MyActions.FIELDS.SIGNED] : data.endpoint.auth ? true : false,
    [MyActions.FIELDS.REGION] : data.auth ? data.endpoint.auth.awsRegion : 'ap-southeast-2',
}}

const handlers = {
    [MyActions.SAVE_DATA] : (state, action) => {
        return state
    },
    [_SYNC_ITEM_S3] : (state, action) => {

        // If got here data is specific for this instrument
        const { name, env, data } = action.payload

        return commonSyncS3(state, name, env, data, translate, MyActions.INSTRUMENT)
    },
    // ...
}


export default (state = {}, action) => {
	
	if (action.type in handlers) {
		return handlers[action.type](state,action);
    }
    return state;
}