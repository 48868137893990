import * as util from '../../../util'
import * as DashboardActions from '../../dashboard/actions'
import { COMMON_FIELDS } from '../../dashboard/common'
import { CLEANUP_INSP, CHANGE_INSP_MODE, CONFIRM_DELETE_INSP } from '../actions';

export const INSTRUMENT  = 'endpoint'
export const SAVE_DATA   = `${INSTRUMENT}.saveData`

export const ENDPOINT_GROUP = 'endpoint'
export const SIGNED_REQ_GROUP = 'aws-sign'
export const DATA_GROUP = 'data'

// This instruments S3 bucket folder - each instrument can have its own folder in the app-support-myer bucket
export const S3_FOLDER = '<instrument>' // Instrument folder/name goes here

export const FIELDS = {
    DESCRIPTION : COMMON_FIELDS.DESCRIPTION,
    SCHEDULE : COMMON_FIELDS.SCHEDULE,
    STATE   : COMMON_FIELDS.STATE,

    //...
}



export function deleteInsp(parent, insp) {

   return (dispatch, getState) => {

        dispatch({
            type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
        })

        // Do asynchronously
        const tout = setTimeout(() => {

            // TODO ....
            // ....
            
            dispatch({
                type : CONFIRM_DELETE_INSP,
                payload: { parent: parent, insp: insp},
            })

        }, 0)

        clearTimeout(tout)
    }
}

export function save(parent, item) {

	return (dispatch, getState) => {

		dispatch({
			type : DashboardActions.SAVING,   // Indicate we are saving ... to show busy
		})

		// Do asynchronously
		const tout = setTimeout(() => {

            // Dispatch rest call - which adds to s3 or updates s3
            const level = util.level(item.name)

            const state = getState().dashboard
            const changed = level === 0 ? state.save.changed : state.inspection.levels[level-1].save.changed 

            // Merge what's changed with the existing data
///            const raw = { ...item.data, ...changed}
            
            // Assume for now parent is app, if level is 2 the have to find app
            // Using first component of FQN
///            const app = parent

            // If name has changed - we need to change the name 
            // TODO - at the moment changing name on root level is not supported - need functionality that goes
            // and changes the name on all children and updates the s3 file names (deletes old and creates new ones) 
            // Name must be the fully qualified name
            const name = changed.name !== undefined ? `${parent.name}.${changed.name}` : item.name

            // TODO .... 
            // ....

            dispatch({
                type : CLEANUP_INSP,
                payload : { parent, item, name }  // name will be different, if this is a new inspection --- cleanup needs to handle
            })
            // Dispatch change mode
            dispatch({
                type : CHANGE_INSP_MODE,
                payload : { parent, insp: item, mode: DashboardActions.MODES.VIEW }
            })
      

            clearTimeout(tout)	
        }, 0)

    }

}





