import React from 'react'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
    };
 
    return (
        <div style = {{...styles}}>
            TODO....
        </div>
    )
})