import React from 'react'
import { Line } from 'react-chartjs-2';
import GraphConfigView from '../graphConfigView'

import * as util from '../../../util'

const transform = (data) => {
    return {}
}


/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
   return (
    <div/>
  ) 
});