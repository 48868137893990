import React from 'react'
import CodeTag from 'components/basic/CodeTag'




export default () => (
    <div className='doc' style={{padding:'20px'}}>
<h2>Schedule Field</h2>

<p>This field takes a cron expression in the form of <em>cron(...)</em> or <em>rate(...)</em> expressions</p>

<p>These are described below</p>

<h3>Cron Expressions</h3>

<p>Cron expressions have six required fields, which are separated by white space.</p>

<h4>Syntax</h4>

<p><code>cron(Minutes Hours Day-of-month Month Day-of-week Year)</code></p>

<table>
<thead>
<tr>
  <th>Field</th>
  <th>Values</th>
  <th>Wildcards</th>
</tr>
</thead>
<tbody>
<tr>
  <td>Minutes</td>
  <td>0-59</td>
  <td>, - * /</td>
</tr>
<tr>
  <td>Hours</td>
  <td>0-23</td>
  <td>, - * /</td>
</tr>
<tr>
  <td>Day-of-month</td>
  <td>1-31</td>
  <td>, - * ? / L W</td>
</tr>
<tr>
  <td>Month</td>
  <td>1-12 or JAN-DEC</td>
  <td>, - * /</td>
</tr>
<tr>
  <td>Day-of-week</td>
  <td>1-7 or SUN-SAT</td>
  <td>, - * ? L #</td>
</tr>
<tr>
  <td>Year</td>
  <td>1970-2199</td>
  <td>, - * /</td>
</tr>
</tbody>
</table>

<h4>Wildcards</h4>

<ul>
<li><p>The , (comma) wildcard includes additional values. In the Month field, JAN,FEB,MAR would include January, February, and March.</p></li>
<li><p>The - (dash) wildcard specifies ranges. In the Day field, 1-15 would include days 1 through 15 of the specified month.</p></li>
<li><p>The * (asterisk) wildcard includes all values in the field. In the Hours field, * would include every hour. You cannot use * in both the Day-of-month and Day-of-week fields. If you use it in one, you must use ? in the other.</p></li>
<li><p>The / (forward slash) wildcard specifies increments. In the Minutes field, you could enter 1/10 to specify every tenth minute, starting from the first minute of the hour (for example, the 11th, 21st, and 31st minute, and so on).</p></li>
<li><p>The ? (question mark) wildcard specifies one or another. In the Day-of-month field you could enter 7 and if you didn't care what day of the week the 7th was, you could enter ? in the Day-of-week field.</p></li>
<li><p>The L wildcard in the Day-of-month or Day-of-week fields specifies the last day of the month or week.</p></li>
<li><p>The W wildcard in the Day-of-month field specifies a weekday. In the Day-of-month field, 3W specifies the day closest to the third weekday of the month.</p></li>
<li><p>The # wildcard in the Day-of-week field specifies a certain instance of the specified day of the week within a month. For example, 3#2 would be the second Tuesday of the month: the 3 refers to Tuesday because it is the third day of each week, and the 2 refers to the second day of that type within the month.</p></li>
</ul>

<p>For more detail and examples, refer to <a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html" target="_blank">AWS Scheduled Events</a></p>

<h4>Examples</h4>

<table>
<thead>
<tr>
  <th></th>
  <th></th>
</tr>
</thead>
<tbody>
<tr>
  <td>cron(0 10 * * ? *)</td>
  <td>Run at 10:00 am (UTC) every day</td>
</tr>
<tr>
  <td>cron(15 12 * * ? *)</td>
  <td>Run at 12:15 pm (UTC) every day</td>
</tr>
<tr>
  <td>cron(0 18 ? * MON-FRI *)</td>
  <td>Run at 6:00 pm (UTC) every Monday through Friday</td>
</tr>
<tr>
  <td>cron(0 8 1 * ? *)</td>
  <td>Run at 8:00 am (UTC) every 1st day of the month</td>
</tr>
<tr>
  <td>cron(0/15 * * * ? *)</td>
  <td>Run every 15 minutes</td>
</tr>
<tr>
  <td>cron(0/10 * ? * MON-FRI *)</td>
  <td>Run every 10 minutes Monday through Friday</td>
</tr>
<tr>
  <td>cron(0/5 8-17 ? * MON-FRI *)</td>
  <td>Run every 5 minutes Monday through Friday between 8:00 am and 5:55 pm (UTC)</td>
</tr>
</tbody>
</table>

<h3>Rate Expressions</h3>

<p>A rate expression starts when you create the scheduled event rule, and then runs on its defined schedule.</p>

<p>Rate expressions have two required fields. Fields are separated by white space.</p>

<h4>Syntax</h4>

<p><code>rate(value unit)</code></p>

<h5>value</h5>

<p>A positive number.</p>

<h5>unit</h5>

<p>The unit of time. Different units are required for values of 1, such as minute, and values over 1, such as minutes.</p>

<p>Valid values: minute | minutes | hour | hours | day | days</p>

<blockquote>
  <p>A value such as '1 minutes' will result in the AWS event not being created, as it will throw an exception. If something is '1' the unit must not be plural e.g. '1 hours' must be '1 hour*</p>
</blockquote>

<p>For more detail refer to <a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html" target="_blank">AWS Scheduled Events</a></p>

<h4>Examples</h4>

<table>
<thead>
<tr>
  <th>Expression</th>
  <th></th>
</tr>
</thead>
<tbody>
<tr>
  <td>rate(1 minute)</td>
  <td>Run every minute</td>
</tr>
<tr>
  <td>rate(5 minutes)</td>
  <td>Run every 5 minutes</td>
</tr>
<tr>
  <td>rate(1 hour)</td>
  <td>Run overy hour</td>
</tr>
</tbody>
</table>

    </div>
)