import AWS from 'aws-sdk'
import * as Config from '../config'
import * as util from '../util'

const apigClientFactory = require('aws-api-gateway-client').default;

AWS.config.update(Config.S3_ACCESS)
export const Clients = {
    APIGateway :  apigClientFactory.newClient(Config.API_ACCESS),
    S3 : new AWS.S3(),
    DDB: new AWS.DynamoDB.DocumentClient()
}

export const updateAPIGatewayService = (config, user) => {
    Clients.APIGateway = apigClientFactory.newClient(config);
}

export const updateAwsService = (config, user) => {
    AWS.config.update(config)

    Clients.S3 = new AWS.S3()
    Clients.DDB = new AWS.DynamoDB.DocumentClient()

}

/**
 * If embedded in a mobile app notify with credentials for app to access AWS services 
 * 
 * @param {*} config 
 * @param {*} user 
 */
export const updateMobileApp = (config, user) => {
    	// IOS
		const iosHandler = util.valueOf("webkit.messageHandlers.signedin", window)
		if (iosHandler) {
			console.log("Running in embedded browser")
			iosHandler.postMessage({"accessKey":config.accessKey, "secretKey":config.secretKey, "sessionToken":config.sessionToken});
		} else {
			console.log("Running in browser")
        }
        
        // Android - TODO...

}