import * as MyActions from './actions'
import { _SYNC_ITEM_S3 } from '../../dashboard/common'
import { commonSyncS3 } from '../commonInst'


const translate = (data) => { return {

}}

const handlers = {
    [MyActions.SAVE_DATA] : (state, action) => {
        return state
    },
    [_SYNC_ITEM_S3] : (state, action) => {

        // If got here data is specific for this instrument
        const { name, env, data } = action.payload

        return commonSyncS3(state, name, env, data, translate, MyActions.INSTRUMENT)
    },
    // ...
}


export default (state = {}, action) => {
	
	if (action.type in handlers) {
		return handlers[action.type](state,action);
    }
    return state;
}