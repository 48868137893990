import React from 'react'
import { Segment } from 'semantic-ui-react';

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
        padding: '20px'
    };
    const { item, /*app, item, actions*/} = props   // item is app - if root level, else inspection instance
    return (
        <div style = {{...styles}}>
            <h2>EndPoint Check Instrument</h2>
            <Segment style={{overflow: 'auto'}}>
                <pre>
                    {JSON.stringify(item.data, null, 2)}
                </pre>
            </Segment>
        </div>
    );
});