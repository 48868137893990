import React from 'react'
import CodeTag from 'components/basic/CodeTag'




export default () => (
    <div className='doc' style={{padding:'20px'}}>
<h2>State Field</h2>

<p>Enable or Disable the Inspection</p>

<p>Setting this to <em>Disabled</em> or <em>Enabled</em> immediately disables or enables the AWS event that triggers this inspection </p>

    </div>
)