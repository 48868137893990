import React from 'react'
import * as Actions from './actions'
import * as Util from '../../../util'
import ScheduleForm from '../scheduleForm'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
    };
    const { app, item, actions, dashboard} = props   // item is app - if root level, else inspection instance
    const data = item.data || {}
    // Merge with any data changed so far
    const level = Util.level(item.name)
    const save = dashboard.inspection.levels[level-1].save
    const merged = save ? { ...data, ...save.changed } : data
    const parent = Util.traverse(dashboard, Util.parentName(item.name), app.env)
    const set = (field, value) => actions.editInsp(parent, item, { att: field, value: value})  
    return (
        <div style = {{...styles}}>
          <ScheduleForm dashboard={dashboard} merged={merged} level={level} set={set} fields={Actions.FIELDS} />
        </div>
    )
})