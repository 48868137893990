import React from 'react'
import CodeTag from 'components/basic/CodeTag'




export default () => (
    <div className='doc' style={{padding:'20px'}}>
<h2>Instruments</h2>

<p>This document provides general information to help with building instruments.</p>

<p>Instruments have both a back end part and the front end GUI part. </p>

<h3>Instrument Backend</h3>

<p>The backend of an instrument, is made up of one or more Lambdas, that may use the shared code <a href="#llayers">Lambda Layers</a></p>

<p>These lambdas usually perform the following tasks:
1. Configure an Event in Cloudformation
2. Update the DDB table: AppSupportMonitor with a new entry or update an existing entries timestamp
3. Perform a measurement 
4. Store the measurement timestamped in the sequence of measurements, also stored in the AppSupportMonitor DDB table for the particular inspection
5. Send an SNS notification</p>

<p>The functionality for all 1, 2, 4 and 5 is functionality already available in the Lambda Layers and are simply one line calls to these functions in the Layers.</p>

<p>Most of the code that would be developed is in 3) for example in the 'EC2-check' instrument, it would be to call the AWS API's to determine all EC2 instances running</p>

<p>All instruments backend Lambdas, currently reside in the <em>app-support-team/awshosted-monitoring-system</em> </p>

<p>Under the folder <em>lambda/instruments/{'<instrument name>'}/</em> i.e. you will need to create a new sub folder for your instrument in this folder.</p>

<p>However they do not have to be in this git repository and can be its own project within its own git repository, completely isolated from the system repository. </p>

<p>To trigger your initial lambda once a configuration for an inspection that has been created and/or updated, an s3 event must exist on the Lambda</p>

<p>Another thing important to remember, are services such as S3 and CloudWatch (Events) need to have permission to invoke the Lambdas. Currently there seems to be no facility in AWS console to add these permissions, however they can be added via a cloudformation script or AWS command line </p>

<p>e.g. To add a permission to CloudWatch Events
<code>
aws lambda add-permission --statement-id "TrustCWEToInvokeMyLambdaFunction" --action "lambda:InvokeFunction" --principal "events.amazonaws.com" --function-name "arn:aws:lambda:ap-southeast-2:712164089289:function:AppSupport_EC2Check" --profile test --region ap-southeast-2
</code></p>

<blockquote>
  <p>A cloudformation script will exist (Heat has already started developing these) for each instrument, which will have these permissions built in with a cloudformation template that can be copied and modified accordingly for new instruments</p>
</blockquote>

<h3>Instrument Frontend</h3>

<p>The frontend code is purely for presenting to the end user and obtaining configuration data for inspections. It does not perform any logic other than parameter validation and calling the appropriate backend APIs to store and retrieve configuration and status of inspections.</p>

<p>The code for performing this is fully built into the AMSDashboard already. The AMSDashboard is a single page app website (static s3 website hosted via CloudFront) built using React.</p>

<p>To add a new instrument to the Dashboard, the following needs to be done:</p>

<ol>
<li>In the front end there is template folder under <em>src/components/instruments/instrument-template</em></li>
<li>Copy this folder to the same parent folder and name with instrument name <em>src/components/instruments/{'<instrument name>'}</em>  e.g. <em>src/components/instruments/restarts</em></li>
<li>The folder contains stubs of the following files you will need - the most important are the view.js and the form.js</li>
</ol>

<p>| File                  |  Comment  |
| view.js               |  The view.js module specifies what is presented, for an inspection of your instrument, when the Dashboard is in View mode
| form.js               |  This module is for parameterising your instrument, and is basically a form containing all the parameters that your instrument requires
| actions.js            |  Mostly should not need to do anything here, other that the translate function and FIELDS constant, which are used to translate your instruments configuration JSON structure to a flattened structure, that the Tool can use in a generic manner to perform validation, saving, mandatory parameter checking, presenting etc. of the instruments parameters
| reducer.js            | should not need to do anything here
| validators            | If any of your parameters need to be validated, the validators need to be placed in this module - see 'endpoint' to see how this is done</p>

<ol>
<li>Your instrument needs to added to the instruments.js file in <em>src/components/instruments/instruments.js</em> in the INSTRUMENTS constant, see below</li>
</ol>

<p><code>// Add Instruments here
    export const INSTRUMENTS = [
    'endpoint',
    'restarts',
    'ec2-check',
    'ec2-status-change'
    //... 
]
</code></p>

<p>Your instrument s</p>

<h3><a name='llayers'></a>Lambda Layers</h3>

<p>The following lambda layers have been developed to simplify and expedite development of instruments.</p>

<p>[Lamba layers]</p>

<blockquote>
  <p>Most of the code in the Lambda layers use functional oriented coding paradigms (OO also used) and is designed to be highly extensible to mitigate the need for unit testing etc. and to facilitate a sound foundation to build on for more rapid development of future instruments.</p>
</blockquote>

<p>The Lamda Layers reside in the <em>app-support-team/lambda-layers</em> git repository in the Myer gitlab repository - <a href="https://gitlab.aws.myer.com.au/">Gitlab</a></p>

<p>These are covered in more detail below</p>

<h4>Foundation</h4>

<p>This contains very generic and foundational code, that can be used by AMSDashboard, instruments and/or any Myer application or Tool not related to the AMS Dashboard</p>

<p>The following sections, cover some of the main modules and their functions - </p>

<h5>Common - common.js</h5>

<p>| Functions               | 
| getCookieParam          | Decodes HTTP Cookies
| interpolate             | Interpolates strings with substitution parameters with the format: {'{%<key>%}'}
| escapeJSON              | Escapes a JSON structured String
| isNullOrEmpty           | String check
| isNullOrWhiteSpace      | Similar to above, but string could have spaces in it
| isObject                | Check if a variable is a javascript object</p>

<h5>DDB - ddb.js</h5>

<p>This module provides a curried function for easily obtaining, updating or deleting an entry in a DynamoDB table, without the verbose code required when using the AWS SDK directly to perform these tasks</p>

<p>Below is an code extract for how to use this module:</p>

<pre>
<CodeTag code={String.raw`
    const ddb = myer_ddb.on(new AWS.DynamoDB())
    const tbl = ddb("TeamMemberSignin")
    const appsTbl = ddb("TeamMemberAuthApps")

    const handleErr = (err, retryUrl = config.SAML.Request.redirectURL) => {
        context.fail(myer_base.errorResponse({ Error: err},retryUrl))
    }


    tbl("GUID", user.guid).update(myer_ddb.vargs("assertion",SAMLAssertion)).then((result) => {
           // ...
        }, (err) => {
            handleErr(err) 
        })
    }).catch((err)  => {     
        let response=errResponse.replace(/{%err%}/, JSON.stringify({ Error: err }))      
        context.fail(response)
    })`}/>
</pre>

<p>This might look a bit overwhelming, however what is happening here is quite simple</p>

<pre>
<CodeTag code={String.raw`
    const ddb = myer_ddb.on(new AWS.DynamoDB())
`}/>
</pre>

<p>The main function that the <em>ddb</em> module exposes is <em>on</em>, which takes the DynamoDB SDK object as an argument which returns a function. The function returned is a closure and now includes the SDK object, this is referred to as currying. Whenever the returned function is called it already knows (has a reference to) the DynamodDB SDK object</p>

<p>The function returned, itself takes the Table name as an argument. And this in turn returns a function, which now takes the DynamoDB table, so we can set the DynamoDB table as follows:</p>

<pre>
<CodeTag code={String.raw`
      const tbl = ddb("TeamMemberSignin")
`}/>
</pre>

<p>We Can now perform updates on the table, delete or get operations as follows</p>

<p>Update table</p>

<pre>
<CodeTag code={String.raw`
    tbl("GUID", user.guid).update(myer_ddb.vargs("assertion",SAMLAssertion)).then((result) => {
           // ...
        }, (err) => {
            handleErr(err) 
        })
    }).catch((err)  => {     
        let response=errResponse.replace(/{%err%}/, JSON.stringify({ Error: err }))      
        context.fail(response)
    })`}/>
`}/>
</pre>

<blockquote>
  <p>Note: The operations return a Promise, hence the then/catch code above</p>
</blockquote>

<p>Delete entry in the table:</p>

<pre>
<CodeTag code={String.raw`
      tbl("GUID", user.guid).delete()
`}/>
</pre>

<p>Get an entry</p>

<pre>
<CodeTag code={String.raw`
      tbl("GUID", user.guid).get()
`}/>
</pre>

<p>What might still be confusing is the vargs function. The vargs function allows us to pass a list of arguments, without having to pass on array of objects.</p>

<p>e.g. </p>

<pre>
<CodeTag code={String.raw`
      myer_ddb.vargs("timestamp",Date.now().toString())("instrument",instrument)
`}/>
</pre>

<p>is equivalent to the AWS SDK format for specifying values:</p>

<pre>
<CodeTag code={String.raw`
    [ 
        {   key: "timestamp", 
            val: Date.now().toString(),
            type: "S"     // "S" is default, to change type, will need to pass as 3rd arg e.g.
                          //       myer_ddb.vargs("timestamp",Date.now().toString(),"N")("instrument",instrument)
        }, 
        {   key: "instrument",
            val: instrument
            type: "S"
        }
    ]
`}/>
</pre>

<p>vargs also works for composite keys (DynamoDB Partition and Sort key)</p>

<p>E.g.</p>

<pre>
<CodeTag code={String.raw`
      tbl(myer_ddb.vargs("FQN", FQN)("Environment",env)).update(myer_ddb.vargs("state",status.toString(),"N")).then((entry) => {
          // ...
`}/>
</pre>

<blockquote>
  <p>Note: For update, if there is only one field, vargs must still be used, however this is not the case for a non composite key which does need for vargs </p>
</blockquote>

<p>E.g.</p>

<pre>
<CodeTag code={String.raw`
    tbl("GUID", user.guid).update(myer_ddb.vargs("assertion",SAMLAssertion)).then((result) => {
        // ...
`}/>
</pre>

<blockquote>
  <p>Note: myer_ddb would have been imported as follows e.g. </p>
</blockquote>

<pre>
<CodeTag code={String.raw`
    const myer_ddb = require('myer/ddb')
`}/>
</pre>

<h4>SAML Auth</h4>

<p>This is the least interesting, for instrument development as all the authentication requirements are already handled by the AMSDashboard and not necessarily required by instruments</p>

<h4>Support Dashboard</h4>

<p>This Layer contains code common to AMSDashboard system and Instruments.</p>

<p>| Module            | Comment
| cw-config         | Has one function which handles the S3 Event, this is an earlier version of the more generic cw-config-events module and creates/deletes events where there is 1-1 mapping of event to target <br />
| cw-config-events  | More generalised for handling cases of a gneric event, with multiple Event targets (1-Many targets - such as required by EC2-status-check instrument) where a target is specific to an inspection
| metric            | This module provides functions for storing a measurment to the timestamped sequence of measurements for an inspection, and takes care of removing old entries once the max number of entries is reached. An instrument can optionally specify how the maximum length of the sequence, which defaults to 10
| notify            | This module provides a simple single entry for sending a notification and will take care of sending this to SNS in all the formats necessary to support all required delivery protocols
| util              | Basically has constants, such STATES (Green, Amber, Red, New) for decoding the s3 object name and decoding a fully qualified name to access its components</p>

<h3>Documentation - Info Icon</h3>

<p>This section describes how to add documentation to your instrument</p>

<p>Documentation is created using <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">markdown</a></p>

<p>You will need gulp installed, and the modules specified in the <em>gulpfile</em> installed. Gulp must be running, so that it generates the html/js code while .md files are created and/or modified.</p>

<p>In your instrument folder, create a doc folder. Create a main.md file in this folder. Type documentation, using markdown syntax. The main.md file, will be the document that gets displayed when a user presses the info icon in far right of instrument panel</p>

<p><img src="images/instrument-info.jpg" alt="Info icon" /></p>

<blockquote>
  <p>Place all images in the public/images folder</p>
</blockquote>

<p>If gulp is running correctly, you should see the main.js file created/updated every time you save the main.md file</p>

<h4>Adding info icon and documentation to your parameters</h4>

<ol>
<li>In your instruments <em>doc</em> folder, create a md file, entering the documentation in the form of markdown for your field.</li>
<li>If not already created, create a <em>docs.js</em> file in your <em>doc</em> folder, which looks like this:</li>
</ol>

<pre>
<CodeTag code={String.raw`
import React from 'react'

import * as Actions from '../actions' 
import HeadersDoc from './headers'

//import { COMMON_FIELDS } from '../../../dashboard/common'

export default {
    [Actions.INSTRUMENT] : {
        // ... Add fields here
        [Actions.FIELDS.HEADERS] : (<HeadersDoc />),
        // ... More fields
    }
}
`}/>
</pre>

<ol>
<li>Add your field to where it says 'Add fields here'</li>
<li>Import your document as shown for the 'Headers' field example above</li>
<li>In your form.js file, add the <em>info</em> snippet as shown in the example below</li>
</ol>

<pre>
<CodeTag code={String.raw`
    ...
    <FormTextArea {...info(Actions.INSTRUMENT, Actions.FIELDS.HEADERS)} ... />
    ...
`}/>
</pre>

<blockquote>
  <p>Note: If a document is already open, a new document being opened may not be visible as it may slide up behind the open document. Close the open document to see the document behind. </p>
</blockquote>

<h4>Field Validation</h4>

<p>TODO ...</p>

<h3>Future Improvements</h3>

<ol>
<li>Streamline instrument creation. For example, could develop script that prompts for name, lambdas etc, we create all the code stubs and cloudformation script etc. </li>
<li>If a document is already opened, make sure a new document being opened slides above the open document</li>
</ol>

    </div>
)