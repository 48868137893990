import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import './loader2.css'

export default ({children, isLoading, ...other}) => {
    return (
        <div className='loader content'>
             { isLoading ? (
                <Dimmer.Dimmable blurring dimmed={true}>
                    <Dimmer className='modal' active>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>   
                    {children}             
                </Dimmer.Dimmable>
                ) : children }
        </div>
    )
  }

