import React from 'react'
import { Segment } from 'semantic-ui-react';
import CollapsibleSegment from '../basic/collapsibleSegment'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
        padding: '20px'
    };
    const { item, sColour, label, hasGraph, children, graphLabel} = props   
    return (
        <div style = {{...styles}}>
            <h2>{label}</h2>
            { hasGraph ? (
                <div>
                    <h3>{graphLabel}</h3>
                    <Segment color={sColour} style={{overflow: 'auto'}}>
                        { children }
                    </Segment>
                </div> ) : null
            }
            <CollapsibleSegment title='Configuration' color='blue' style={{overflow: 'auto', height: '200px'}}>
                <pre>
                    {JSON.stringify(item.data, null, 2)}
                </pre>
            </CollapsibleSegment>
        </div>
    );
});