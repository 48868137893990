import React from 'react'
import { Icon } from 'semantic-ui-react'
import BasicInput from '../basic/input/basicInput'
import InputWithDropdown from '../basic/input/InputWithDropdown'
import * as Actions from './actions'
import EditFlows from './editFlows'
import { COMMON_FIELDS, error } from './common'


// State Indicator
export const stateCommon = {
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  display: 'inline-block',
  float: 'right',
  //marginRight: '10px'
} 

export const stateBad = {
  backgroundColor: '#cc3232',
}
export const stateWarn = {
  backgroundColor: '#e7b416',
}
export const stateGood = {
  backgroundColor: '#2dc937',
}
export const stateNew = {
  backgroundColor: 'grey',
}

export const stateStyle = {
  [Actions.STATES.RED]    : { ...stateCommon, ...stateBad},
  [Actions.STATES.AMBER]  : { ...stateCommon, ...stateWarn},
  [Actions.STATES.GREEN]  : { ...stateCommon, ...stateGood},
  [Actions.STATES.NEW]    : { ...stateCommon, ...stateNew},
}


/**
 * Top level Panel
 */
export default props => {
  const viewMode = (isEditable, app, changeMode) => {
    return (
      <div style={{display: 'inline'}}>
        <div style={{display: 'inline-block', width: '40%'}}>{app.name}</div>
        <div style={{display: 'inline-block', width: '20%'}}>{app.env}</div>
        <div name='==status' style={stateStyle[app.state || Actions.STATES.NEW]} />
        { isEditable ? 
          <Icon onClick={ (e) => changeMode(app,Actions.MODES.EDIT) } style={{float:'right', marginRight:'20px'}} name='edit outline' /> : null
        }
      </div>
    )
  }

  // TODO make editable
  const editMode = (app, actions, dashboard, hasChildren) => {
    const _hasChildren = hasChildren || false   // If has childern do not allow delete ... children must be first deleted
    // TODO evironments DDB table with extra info related to each environement such as the AWS account ID - for now we just have the workspace env's DeviceAcceleration, Test and Prod h/coded below
    const _name = dashboard.save && dashboard.save.changed && dashboard.save.changed.name ?
      dashboard.save.changed.name : app.name
    const _env = dashboard.save && dashboard.save.changed && dashboard.save.changed.env ?
      dashboard.save.changed.env : app.env
    return (
      <div style={{display: 'inline'}}> 
        { app.name === '<New>' ?   
            <BasicInput name={COMMON_FIELDS.NAME} onChange={(e)=> {
                actions.editApp(app, { att: COMMON_FIELDS.NAME, value: e.target.value})
              }
            } placeholder={_name} {...error(dashboard,COMMON_FIELDS.NAME)}/> 
            : <span>{app.name}</span>
        }
        
        { app.name === '<New>' ? <InputWithDropdown onClick={(e)=> {
                actions.editApp(app, { att: COMMON_FIELDS.ENV, value: e.target.textContent})
              }} {...error(dashboard,COMMON_FIELDS.ENV)} style={{marginLeft: '80px'}} val={_env} selections={['Dev','Test','Prod']} hdr='Environment' /> :
          <span style={{paddingLeft: '20%'}}>{app.env}</span>
        }
        <EditFlows {...props} item={app} actions={actions} deleteEnabled={!_hasChildren}/>
      </div>
    )
  }
  const { isEditable, app, mode, actions, dashboard, hasChildren} = props
  return (isEditable && mode===Actions.MODES.EDIT) ? editMode(app, actions, dashboard, hasChildren) : viewMode(isEditable, app, actions.changeMode)
}
            
            
  