import React from 'react'
import { Input, Label } from 'semantic-ui-react'
import './input.css'
import * as Util from '../../../util'

export default ({placeholder, onChange, errorMssg}) => {
  return (
    <div className='input-with-error'>
      <Input name='name' {...Util.getConditionalProps(errorMssg)} onChange={onChange} placeholder={placeholder} />
      { errorMssg ? 
          <p className='error-message'>
            ! {errorMssg}
          </p> : null
      }
  </div>
  )
}