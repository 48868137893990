import React from 'react'
import ValidationError from '../basic/input/validationError'

import * as Util from '../../util'

import { InstrumentDocs } from '../instruments/instruments'
import TopLevelDoc from '../../doc'


// Had to move this to its own file - because actions.js was causing issue when
// imported into instruments reducer
export const _SYNC_ITEM_S3 = 'sync-item-s3'    // Sync an app/inspection with its config data in S3

export const COMMON_FIELDS = {
    NAME : 'name',
    SCHEDULE: 'schedule',
    DESCRIPTION : 'description',
    STATE   : 'state',
    ENV     : 'env',
    TYPE    : 'type',    // Instrument
}

export const GENERAL = 'General'

// Common validators
export const nameValidator =  (field, value, context) => {
    // Do validation, else throw err

    // When a copy - copy alreade 
    if (value.match(/\.\<Copy/g)) {
        throw new ValidationError('Name is mandatory','Name must be specified, it cannot be <Copy ...>')
    }

    // Name cannot already exist - TODO if different env then it is okay
    const exists = context.items.filter( item => item.name === value && item.env === context.changedFields[COMMON_FIELDS.ENV])
    if (exists.length > 0) {
        throw new ValidationError('This app already exists','Name must be unique, it cannot be the same as another app')
    }

    // and must not be new
    if (value.match(/<New>/g)) {
        throw new ValidationError('Name is mandatory','Name must be specified, it cannot contain <New>')
    }

    // Name cannot have a '.' in it --- used for qualifying (full name) 
    if (value.match(/.*\..*/g)) {
        throw new ValidationError('Name cannot contain a "." character', null)
    }
}

const schedRegex = /^(rate\(((1 (hour|minute|day))|(\d+ (hours|minutes|days)))\))|(cron\(\s*($|#|\w+\s*=|(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[01]?\d|2[0-3])(?:(?:-|\/|,)(?:[01]?\d|2[0-3]))?(?:,(?:[01]?\d|2[0-3])(?:(?:-|\/|,)(?:[01]?\d|2[0-3]))?)*)\s+(\?|\*|(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|,)(?:0?[1-9]|[12]\d|3[01]))?(?:,(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|,)(?:0?[1-9]|[12]\d|3[01]))?)*)\s+(\?|\*|(?:[1-9]|1[012])(?:(?:-|\/|,)(?:[1-9]|1[012]))?(?:L|W|#)?(?:[1-9]|1[012])?(?:,(?:[1-9]|1[012])(?:(?:-|\/|,)(?:[1-9]|1[012]))?(?:L|W|#)?(?:[1-9]|1[012])?)*|\?|\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\s+(\?|\*|(?:[0-6])(?:(?:-|\/|,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|\/|,|#)(?:[0-6]))?(?:L)?)*|\?|\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\s)+(\?|\*|(?:|\d{4})(?:(?:-|\/|,)(?:|\d{4}))?(?:,(?:|\d{4})(?:(?:-|\/|,)(?:|\d{4}))?)*))\))$/m;
export const scheduleValidator =  (field, value, context) => {

    if (!schedRegex.test(value.trim())) {
        throw new ValidationError('Invalid format', 'For more information, see https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html#CronExpressions')
    }
}

export const jsonValidator =  (field, value, context) => {
    try {
        JSON.parse(value);
    } catch (e) {
        throw new ValidationError('Invalid JSON syntax', e.message)
    }
}


export const hasError = (dashboard, att, level=0) => {
    const save = level===0 ? dashboard.save : dashboard.inspection.levels[level-1].save
    return save && save.invalid && save.invalid[att] 
}
export const getError = (dashboard, att, level=0) => {
    const save = level===0 ? dashboard.save : dashboard.inspection.levels[level-1].save
    return save.invalid[att]
}

export const error = (dashboard, field, level=0) => hasError(dashboard,field, level) ? { errorMssg: getError(dashboard, field, level).exception.message}: {}

// May take time to load
var Docs = null
var getDocs = (docs) => {
    if (Docs === null) {
        Docs = {
            'Top': {
                'Bar' : (<TopLevelDoc />)
            },    // Top level docs
            ...docs   // Instrument docs
        }
        console.log("Docs :", Docs)
        getDocs = (docs) => Docs
    }
    return Docs
}

export const info = (instrument, field) => {
    if (Docs === null) {
        InstrumentDocs.then((docs) => {
            getDocs(docs)
        })
    } else {
        const _info = Util.valueOf(`${instrument}.${field}`, Docs)
        return _info ? { info: _info}: {}
    }
    return {}
}
