import * as util from '../../../util'
import { COMMON_FIELDS } from '../../dashboard/common'
import * as Actions from '../actions'

export const INSTRUMENT  = 'sqs-depth'
export const SAVE_DATA   = `${INSTRUMENT}.saveData`

// This instruments S3 bucket folder - each instrument can have its own folder in the app-support-myer bucket
export const S3_FOLDER = INSTRUMENT // S3 folder where inspection config data will be stored

export const FIELDS = {
    DESCRIPTION : COMMON_FIELDS.DESCRIPTION,
    SCHEDULE : COMMON_FIELDS.SCHEDULE,
    STATE   : COMMON_FIELDS.STATE,

    //...
    QUEUES       : `${INSTRUMENT}.queues`,
    TOLERANCE    : `${INSTRUMENT}.tolerance`
}



export function deleteInsp(parent, insp) {
    return Actions.deleteInspS3(parent, insp,(s3name, raw, app) => {
        return {
            meta: {
                name : s3name,
                instrument : INSTRUMENT // This instrument
            }
        }
    },{})
}

const translateFn =  (s3name, raw, app) => {
    return {
        meta: {
            name : s3name, // Encodes fullname and env
            instrument : INSTRUMENT // This instrument
        },
        data: {
            name : `${s3name}`,  // Cloudwatch event - rule name - make same as s3 object name
            description: "TODO .....",
            scheduleExpression : raw[FIELDS.SCHEDULE],
            state : raw[FIELDS.STATE],
            application : app.name,  
            [INSTRUMENT]: {
                queues : raw[FIELDS.QUEUES].split(','),
                tolerance : raw[FIELDS.TOLERANCE]
            }
        }
    }
}

export function save(parent, item, isMove = false, newName = '', compFn = null) { 
  
    return isMove ? 
        Actions.moveInspS3(parent, item, translateFn, newName, {}, compFn) :
        Actions.saveInspS3(parent, item, translateFn, {}, compFn)
}

export function runInsp(parent, item, compFn = null) {   
    return Actions.runInspS3(parent, item, translateFn, {}, compFn)
}

export function stopInsp(parent, item, compFn = null) {   
    return Actions.stopInspS3(parent, item, translateFn, {}, compFn)
}
