import { combineReducers } from 'redux';
import  main from './services/reducers';
import dashboard from './components/dashboard/reducers'

/*
// Add instrument reducers here
import endpoint from './components/instruments/endpoint/reducers'
*/

export default combineReducers({
    main,
    dashboard,
    /*
    // Instruments
    endpoint,
    */
});