import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import Loader from '../basic/loader/main2'

import { Icon } from 'semantic-ui-react'
import RootPanelTitle from './RootPanelTitle'
import SubPanelTitle from './SubPanelTitle'
import * as Actions from './actions'
//import * as MainActions from '../../main/actions'
import * as InspActions from '../instruments/actions'

import Instruments from '../instruments/instruments'

const MAX_DEPTH = 3  // Max depth of levels

const LockableAA = (props) => { 
   return props.locked > -1 ?
    (<Accordion.Accordion activeIndex={[props.locked]} {...props} />) :
    (<Accordion.Accordion {...props} />)
}

var done = false

export class AccordionRoot extends Component {


  componentDidMount() {

  }

  componentWillUnmount() {

  }

  componentDidUpdate(prevProps, prevState) {
    if (!done) {
      // Do once only
      var active = document.querySelectorAll("div.content.active")
      if (active.length > 0) {
        active[active.length - 1].scrollIntoView()
      }
      done = true
    } 
  }

  getContent = ( isEditable, app, insp, level) => {
    const save = this.props.dashboard.inspection.levels[level-2].save
    const type = save && save.changed ? save.changed.type || insp.type : insp.type
    const Instrument = Instruments[
      (isEditable && insp.mode === Actions.MODES.EDIT) ? 
        'forms' : 'views'][type]
    return (
      <Instrument app={app} item={insp} dashboard={this.props.dashboard} actions={{ editInsp: this.props.editInsp}}/>
    )
  }

  getActiveIndex = (level) => {
    if (this.props.dashboard.inspection.levels[level-1].keepOpen) {
      return this.props.dashboard.inspection.levels[level-1].keepOpen.index
    } 
    return -1
  }

  getSubPanels = (filterMap, app, isEditable, inspections, level, inspection = null) => {
    const handleClick = (e) => {
      e.preventDefault();
      //console.log('The link was clicked.');
      console.log("level: ", level)
      console.log("inspection: ", inspection)
      this.props.addInsp(level > 1 ? inspection : app);
    }
    const handleGrpClick = (e) => {
      e.preventDefault();
      //console.log('The group was clicked.');
      this.props.addGroup(app);
    }
    const activeIndex = this.getActiveIndex(level)
    const defaultActive = (app.defaultActive > -1) ? app.defaultActive : -1
    return (
      <div>
        { inspection != null ? this.getContent(isEditable, app, inspection, level) : (<h4>Inspections</h4>) /*or app TODO*/ }
        { inspections.length > 0 ? (<LockableAA exclusive={false} defaultActiveIndex={[defaultActive]} locked={activeIndex} panels={
            inspections.map( inspection => {
              return {
                key: inspection.name,
                title: { content: (  // Should include name --- env --- health ---- edit/save
                  <SubPanelTitle app={app} 
                      insp={inspection} 
                      isEditable={isEditable} 
                      mode={inspection.mode} 
                      actions={{
                        changeMode: this.props.changeInspMode,
                        deleteInsp: this.props.deleteInsp,  
                        copyInsp: this.props.copyInsp,
                        deleteConfirm: this.props.confirmDeleteInsp, 
                        deleteAbort: this.props.abortDeleteInsp,
                        editInsp: this.props.editInsp,
                        editContinue: this.props.editInspContinue,
                        editDiscard: this.props.editInspDiscard,
                        save: this.props.saveInsp,
                        moveInsp: this.props.moveInsp,
                        confirmMoveInsp: this.props.confirmMoveInsp,
                        abortMoveInsp: this.props.abortMoveInsp,
                        runInsp: this.props.runInsp,
                        stopInsp: this.props.stopInsp
                      }}
                      dashboard={this.props.dashboard}
                      hasChildren={inspection.inspections && inspection.inspections.length > 0}
                      level={level}
                    />
                )},
                content: (level < MAX_DEPTH || inspection.type === 'group') ? { content: this.getSubPanels(filterMap, app, isEditable, inspection.inspections, level+1, inspection) } : null, // level 2
              }
        })} />) : null }
        { isEditable && app.mode === Actions.MODES.EDIT && app.name !== "<New>" && (level < MAX_DEPTH || inspection.type === 'group') ?  // 
            ( <div>
                <Icon onClick={handleClick} link circular inverted color='teal' style={{margin: '20px'}} name='plus' />
                { level < (MAX_DEPTH - 1) ? <Icon onClick={handleGrpClick} link circular inverted color='teal' style={{margin: '20px'}} name='object group outline' /> : null }
              </div>) : null
        }
      </div>
    )
  } 

  getRootPanels = () => {
    const { isEditable, apps, filterMap } = this.props
    return apps.map( app => {   // Level 0
      return {
        //level: 1,
        key: `${app.name}@${app.env}`,
        title: { content: (  // Should include name --- env --- health ---- edit/save
            <RootPanelTitle app={app} 
                    isEditable={isEditable} 
                    mode={app.mode} 
                    actions = {{
                      deleteApp: this.props.deleteApp,
                      deleteConfirm: this.props.confirmDeleteApp, 
                      deleteAbort: this.props.abortDeleteApp,
                      changeMode: this.props.changeAppMode,
                      editApp: this.props.editApp,
                      editContinue: this.props.editContinue,
                      editDiscard: this.props.editDiscard,
                      save: this.props.save
                    }}
                    dashboard={this.props.dashboard}
                    hasChildren={app.inspections && app.inspections.length > 0}
            />
        )},
        content: { content: this.getSubPanels(filterMap, app, isEditable, app.inspections, 1)},  // level 1
      }
    })
  }

  render = () => {
    const { isLoading, isEditable, keepOpen, defaultActive } = this.props
    const handleClick = (e) => {
      e.preventDefault();
      //console.log('The link was clicked.');
      this.props.addApp();
    }
    return (
      <Loader isLoading={isLoading}>
        { (keepOpen == null) ?
          <Accordion exclusive={false} defaultActiveIndex={[defaultActive]} panels={this.getRootPanels()} styled /> :
          <Accordion exclusive={false} defaultActiveIndex={[defaultActive]} activeIndex={[keepOpen.index]} panels={this.getRootPanels()} styled />
        }
        { isEditable ? 
            (<Icon onClick={handleClick} link circular inverted color='teal' style={{margin: '20px'}} name='plus' />) : null }
      </Loader>
    )
  }


}

export const mapStateToProps = state => {
  return {
    apps: state.dashboard.apps,
    isEditable: state.main.isEditable,
    isLoading: state.dashboard.isLoading,
    keepOpen: state.dashboard.keepOpen,
    dashboard: state.dashboard,
    defaultActive: state.dashboard.defaultActive,
  }
}

export const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    /*
    // Read dynamodb table
    readDDB : MainActions.readDDB,
    readS3 : MainActions.readS3,
    */

    // App/Root level actions
    changeAppMode: Actions.changeAppMode,
    addApp: Actions.addApp,
    deleteApp: Actions.deleteApp,
    confirmDeleteApp: Actions.confirmDeleteApp,
    abortDeleteApp: Actions.abortDeleteApp,
    editApp: Actions.editApp,
    editContinue: Actions.editContinue,
    editDiscard: Actions.editDiscard,
    save : Actions.save,

    // Instrument/Sublevel actions 
    changeInspMode: InspActions.changeInspMode,
    addInsp: InspActions.addInsp,
    copyInsp: InspActions.copyInsp,
    deleteInsp: InspActions.deleteInsp,
    confirmDeleteInsp: InspActions.confirmDeleteInsp,
    abortDeleteInsp: InspActions.abortDeleteInsp,
    editInsp: InspActions.editInsp,
    editInspContinue: InspActions.editInspContinue,
    editInspDiscard: InspActions.editInspDiscard,
    saveInsp: InspActions.save,

    // Group actions
    addGroup: InspActions.addGroup,
    moveInsp: InspActions.moveInsp,
    confirmMoveInsp: InspActions.confirmMoveInsp,
    abortMoveInsp: InspActions.abortMoveInsp,

    // Common individual/group actions
    stopInsp: InspActions.stopInsp,
    runInsp: InspActions.runInsp,

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccordionRoot)
