import * as util from '../../../util'
import { COMMON_FIELDS } from '../../dashboard/common'
import * as Actions from '../actions'

export const INSTRUMENT  = 'endpoint'
export const SAVE_DATA   = `${INSTRUMENT}.saveData`

export const ENDPOINT_GROUP = 'endpoint'
export const SIGNED_REQ_GROUP = 'aws-sign'
export const DATA_GROUP = 'data'

// This instruments S3 bucket folder - each instrument can have its own folder in the app-support-myer bucket
export const S3_FOLDER = 'endpoint'

export const FIELDS = {
    DESCRIPTION : COMMON_FIELDS.DESCRIPTION,
    SCHEDULE : COMMON_FIELDS.SCHEDULE,
    STATE   : COMMON_FIELDS.STATE,

    // Endpoint group
    METHOD  : `${ENDPOINT_GROUP}.method`,
    HOST    : `${ENDPOINT_GROUP}.host`,
    PATH    : `${ENDPOINT_GROUP}.path`,
    SAVE_RESPONSE : `${ENDPOINT_GROUP}.saveResponse`,
    SCHEME  : `${ENDPOINT_GROUP}.scheme`,
    HEADERS : `${ENDPOINT_GROUP}.headers`,

    // Signed request group
    SIGNED  : `${SIGNED_REQ_GROUP}.signed`,
    TYPE    : `${SIGNED_REQ_GROUP}.type`,
    ENV     : `${SIGNED_REQ_GROUP}.env`,
    REGION  : `${SIGNED_REQ_GROUP}.region`,

    // Data group
    BODY    : `${DATA_GROUP}.body`,
    EXPECTED: `${DATA_GROUP}.expected`,
    // ...
}

export function deleteInsp(parent, insp) {

    return Actions.deleteInspS3(parent, insp,(s3name, raw, app) => {
        return {
            meta: {
                name : s3name,
                instrument : INSTRUMENT // This instrument
            }
        }
    },{})
}

const safeParse = (json) => {
    try {
        return util.isEmpty(json) ? {} : JSON.parse(json)
    } catch (err) {
        console.error("Failed to parse JSON: ", err)
        return "{}"
    }
}

const translateFn =  (s3name, raw, app) => {
    // Construct REST call body
    const body = {
        meta: {
            name : s3name, // Encodes fullname and env
            instrument : 'endpoint' // This instrument
        },
        data: {
            name : `${s3name}`,  // Cloudwatch event - rule name - make same as s3 object name
            description: "TODO .....",
            scheduleExpression : raw[FIELDS.SCHEDULE],
            state : raw[FIELDS.STATE],
            application : app.name,  
            endpoint: {   
                endpoint: {     
                    options: {       
                        method: raw[FIELDS.METHOD], 
                        host: raw[FIELDS.HOST],       
                        path: raw[FIELDS.PATH],       
                        headers: raw[FIELDS.HEADERS] !== undefined && util.isString(raw[FIELDS.HEADERS]) ? safeParse(raw[FIELDS.HEADERS]) : raw[FIELDS.HEADERS] || {},
                    },     
                    saveResponse : raw[FIELDS.SAVE_RESPONSE],
                    scheme: raw[FIELDS.SCHEME],
                    data: raw[FIELDS.BODY] !== undefined && util.isString(raw[FIELDS.BODY]) ? safeParse(raw[FIELDS.BODY]) : raw[FIELDS.BODY] || {},
                },   
                expected: raw[FIELDS.EXPECTED] !== undefined && util.isString(raw[FIELDS.EXPECTED]) ? safeParse(raw[FIELDS.EXPECTED]) : raw[FIELDS.EXPECTED] || {}
            }
        }
    }

    if (raw[FIELDS.SIGNED] === true) {
        body.data.endpoint.auth = {
                awsRegion :  raw[FIELDS.REGION],
                type : "AWS",
                env : app.env
        }
    }

    return body
}

export function save(parent, item, isMove = false, newName = '', compFn = null) { 
  
    return isMove ? 
        Actions.moveInspS3(parent, item, translateFn, newName, {}, compFn) :
        Actions.saveInspS3(parent, item, translateFn, {}, compFn)
}

export function runInsp(parent, item, compFn = null) {   
    return Actions.runInspS3(parent, item, translateFn, {}, compFn)
}

export function stopInsp(parent, item, compFn = null) {   
    return Actions.stopInspS3(parent, item, translateFn, {}, compFn)
}

/*
export function save(parent, item) {
    
    return Actions.saveInspS3(parent, item,(s3name, raw, app) => {
            // Construct REST call body
            const body = {
                meta: {
                    name : s3name, // Encodes fullname and env
                    instrument : 'endpoint' // This instrument
                },
                data: {
                    name : `${s3name}`,  // Cloudwatch event - rule name - make same as s3 object name
                    description: "TODO .....",
                    scheduleExpression : raw[FIELDS.SCHEDULE],
                    state : raw[FIELDS.STATE],
                    application : app.name,  
                    endpoint: {   
                        endpoint: {     
                            options: {       
                                method: raw[FIELDS.METHOD], 
                                host: raw[FIELDS.HOST],       
                                path: raw[FIELDS.PATH],       
                                headers: raw[FIELDS.HEADERS] !== undefined && util.isString(raw[FIELDS.HEADERS]) ? safeParse(raw[FIELDS.HEADERS]) : raw[FIELDS.HEADERS] || {},
                            },     
                            saveResponse : raw[FIELDS.SAVE_RESPONSE],
                            scheme: raw[FIELDS.SCHEME],
                            data: raw[FIELDS.BODY] !== undefined && util.isString(raw[FIELDS.BODY]) ? safeParse(raw[FIELDS.BODY]) : raw[FIELDS.BODY] || {},
                        },   
                        expected: raw[FIELDS.EXPECTED] !== undefined && util.isString(raw[FIELDS.EXPECTED]) ? safeParse(raw[FIELDS.EXPECTED]) : raw[FIELDS.EXPECTED] || {}
                    }
                }
            }

            if (raw[FIELDS.SIGNED] === true) {
                body.data.endpoint.auth = {
                        awsRegion :  raw[FIELDS.REGION],
                        type : "AWS",
                        env : app.env
                }
            }

            return body
        }, {
            //If there are query parameters or headers that need to be sent with the request you can add them here
            headers: {
                *//*"Accept": "text/plain",*//*
                "Content-Type": "application/json"
            },*//*
            queryParams: {
                param0: '',
                param1: ''
            }*//*
        })
}
*/






