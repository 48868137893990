import * as Actions from './actions';
import * as ServiceActions from '../../services/actions';
import data from '../../data';
import inspHandlers from '../instruments/reducers'
import * as util from '../../util'
import { _SYNC_ITEM_S3, nameValidator, COMMON_FIELDS} from './common'
import ValidationError from '../basic/input/validationError'
import { Object } from 'core-js';
//import { FILTER_ACTION } from '../../main/actions'
//import { ItemGroup } from 'semantic-ui-react';

//import data from '../../../data';

// Initially modal - should be passed in - to make Modal re-useable
const initialState = {
    isLoading: data.isLoading,
    apps : data.apps,
    keepOpen: null,
    save: null,
    valid: true,
    inspection: { // Has its own set of save and keepOpen for lower levels - SHOULD BE UPTO MAX_DEPTH specified in dashboard.js. if MAX_DEPTH is increased add more levels correspondingly
        levels: [ {    // 0 - level 1
            keepOpen: null,
            save: null,
        }, {           // 1 - level 2
            keepOpen: null,
            save: null,
        }, {           // 2 - level 3
            keepOpen: null,
            save: null,
        }]
    },
    filterData : { app:[], env: [], inst: []}
}

// Returns cloned found app i.e. a copy
export const findItemInList = (list, item, env) => {
    var _index = -1;
    const name = util.isObject(item) ? item.name : item
    const _env = env || item.env
    if (_env === undefined) {
        throw new Error("findItemInList::Environment not specified. Cannot uniquely identify an item in the list")
    }
    const found = list.filter( (_item, index) => {
        const comp = _item.name === name && _item.env === _env
        if (comp) {
            _index= index // Found index
        }
        return comp    
    })[0]
    if (!found) {
        throw new Error("findItemInList::Item Not Found!")
    }
    return {
        item: {...found},
        index : _index
    }
}

// 

// items passed does not need to be a clone - will be cloned
// if returns null - call lower level handlers inspHandlers
export const changeMode = ( state, action, items, item, save = state.save, keepOpen = state.keepOpen) => {
    const _items = [...items]
    var _keepOpen = {...keepOpen};

    // TODO for all sub levels 0 and 1
    // If lower level ignore - needs to be picked up by lower level
    const __save = state.inspection.levels[0].save
    if (action.type === Actions.CHANGE_APP_MODE && __save && __save.changed && !util.isEmpty(__save.changed)) {
        return null;  // Do nothing - needs to be picked up by lower level
    }

    // If there have been changes do we discard or save if clicked changed items change mode (save) icon
    if (save && save.changed && !util.isEmpty(save.changed)) {
        const _save = {...save, desired: item}  // Keep track of app we wanted to go to
        const changed = findItemInList(_items, save.app);
        _items[changed.index] = changed.item  // A clone
        // Save or cancel changes - if same application/inspection enter save flow, if different application/inspection enter cancel flow
        _items[changed.index].editState = save.app.name === item.name ? Actions.EDIT_STATES.SAVE : Actions.EDIT_STATES.CANCEL  
        return { items: _items, keepOpen: {
            index: changed.index
        }, save: _save}
    }

    var found = {}
    try {
        found = findItemInList(_items, item);

        _items[found.index] = found.item
        _items[found.index].mode = action.payload.mode
    } catch (e) {
        // Ignore - if root level <New> item - which has been removed in cleanup
        if (util.isRoot(item.name) && item.name !== '<New>') {
            console.error(e)
        }
    }
    
    if (action.payload.mode === Actions.MODES.EDIT) {
        // Make sure current selected application entry, if different, is not also/still in edit mode
        //    issue???? keep open is on app/root level - fix for lower levels TODO will f'up
        //     "    should track current edited - in one place
        try {
            if (_keepOpen && 
                _keepOpen.index !== undefined && 
                _keepOpen.index !== found.index &&
                _items[_keepOpen.index].mode === Actions.MODES.EDIT ) {
                    const currentIndex = _keepOpen.index
                    _items[currentIndex] = {..._items[currentIndex]} // clone
                    _items[currentIndex].mode = Actions.MODES.VIEW
            }
        } catch (e) {
            console.error(e)
        }

        _keepOpen = {
            index: found.index
        }
    } else {
        _keepOpen = null
    }
    return { items: _items, keepOpen: _keepOpen, save: save };
}

// items passed in does not have to be a clone of current state
// it will be cloned
export const deleteItem = (state, action, items, item, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, item);

    // Save unchanged state - in case deletion is aborted
    var abort = items

    // Save list with deleted removed - for delete confirmation action
    var confirm = [..._items]
    confirm.splice(found.index,1)
    const _save = { ...save,
        confirm: confirm,
        abort: abort
    }

    _items[found.index] = found.item
    _items[found.index].editState = Actions.EDIT_STATES.DELETE

    return { items: _items, keepOpen: { index: found.index }, save: _save };
}

export const moveItem = (state, action, items, item, save = state.save) => {

    const _items = [...items]
    const found = findItemInList(_items, item);

    const _save = { ...save,
          moveTo: action.payload.toParent
    }

    _items[found.index] = found.item
    _items[found.index].editState = Actions.EDIT_STATES.MOVE

    return { items: _items, keepOpen: { index: found.index }, save: _save };
}

// items passed in does not have to be a clone of current state
// it will be cloned
export const copyItem = (state, action, items, item, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, item);

    // Reset current
    _items[found.index] = found.item
    _items[found.index].editState = Actions.EDIT_STATES.START
    _items[found.index].mode = Actions.MODES.VIEW

    return { items: _items, keepOpen: { index: found.index }, save: save };
}

export const editItem = (state, action, items, item, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, item);

    const _save = {...save};
    _save['app'] = item                      // Could be being changed - Note this will work for insp levels as well
    _save.changed = {..._save.changed} || {} // Save changed attribute's
    const change = action.payload.change
 
    // If value is the same - don't mark as changed a data att and non data att name cannot be the same e.g. env
    const changed = item.data ? item.data[change.att] : item[change.att]
    if (changed && changed === change.value) {
        if (_save.changed[change.att] !== undefined) {
            delete _save.changed[change.att]
            if (util.isEmpty(_save.changed)) {
                _items[found.index].editState = Actions.EDIT_STATES.START // No longer edited
            }
        }
    } else {
        _save.changed[change.att] = change.value
        _items[found.index].editState = Actions.EDIT_STATES.EDITED // Indicate being edited
    }

    // Clear any previous validation failures
    if (_save.invalid && _save.invalid[change.att]) {
        _save.invalid[change.att] = null
    }



    return { items: _items, keepOpen: { index: found.index }, save: _save };
}

// mandatory - list of mandatory fields
// field to validator map
export const validators = {
    [COMMON_FIELDS.NAME] : nameValidator, 
    //... 
}
export const mandatory = [ // Any mandatory item must have a validator else will 
    COMMON_FIELDS.NAME,
    Actions.FIELDS.ENVIRONMENT
]
export const validateItem = (state, action, items, item, _mandatory = mandatory, _validators = validators, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, item);

    const _save = {...save};
    _save.invalid = null;  // reset any previous

    // Check all mandatory fields as well as changed fields against fields validator
    const fieldsToValidate = [..._mandatory, ...Object.keys(_save.changed || {})].filter((value, index, self) => 
        self.indexOf(value) === index  // filter out duplicates
    )

    var failed = false
    fieldsToValidate.forEach( field => {

        var value 
        try {     
            const changed = _save.changed && _save.changed[field]
            value = changed || item.data[field] || item[field]
            if (_mandatory.includes(field)) { // If mandatory, cannot be undefined, null, empty or starts with '<'
                if (value == null || value === undefined || (value && value.trim && value.trim() === '') || (value && value.trim && value.trim()[0]==='<')) {
                    throw new ValidationError(`${field} is mandatory`, null)
                }
            }   
            if (changed && _validators[field]) { // Validate if there is a validator specified
                _validators[field](field,  value, { item: item, items: _items, changedFields: _save.changed})
            }
        } catch (e) {
            failed = true
            _save.invalid = _save.invalid || {}
            _save.invalid[field] = { value: value, exception: e }
        }
    })
    if (failed) {
        _items[found.index].editState = Actions.EDIT_STATES.FAILED
    }

    return { items: _items, keepOpen: { index: found.index }, save: _save };
}


export const editItemContinue = (state, action, items, item, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, save.app || item);
    _items[found.index].editState = Actions.EDIT_STATES.EDITED

    return { items: _items, keepOpen: { index: found.index }, save: {...save, desired: null, desiredLevel: null} };
}

export const editItemDiscard = ( state, action, items, item, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, save.app);
    _items[found.index].editState = Actions.EDIT_STATES.START

    return { items: _items, keepOpen: null, save: {} };
}

export const apiException = ( state, action, items, item, fault, save = state.save) => {
    const _items = [...items]
    const found = findItemInList(_items, item);
    _items[found.index].editState = Actions.EDIT_STATES.FAULT  // General Fault
    _items[found.index].fault = fault

    return { items: _items, keepOpen: { index: found.index }, save: save };
}

export const cleanup = ( state, action, items, item, name = item.name) => {
    const level = util.level(item.name)
    if (level === 0) {
        // To make things simpler - just remove any <New> apps for now if level 0 
        return { items: items.filter( item => item.name !== '<New>'), keepOpen: null, save: {} }
    }

    // else
    const _items = [...items]
    const found = findItemInList(_items, item);
    _items[found.index].editState = Actions.EDIT_STATES.START

    if (name !== item.name) { // There has been a name change
        _items[found.index].name = name

        // TODO - if this is a parent item, i.e. has children then all children names
        // have to also be changed and DDB needs to be updated accordingly
        // as well as all corresponding s3 entries
        // ...
    }


    return { items: _items, keepOpen: null, save: {} };
}


const handlers = {
    'TOGGLE_EDITABLE': (state, action) => {
        // Clear keepOpens  
        const inspection = {...state.inspection}
        inspection.levels.forEach( (item) => {
            item.keepOpen = null
        })
        return {
          ...state,
          inspection,
          keepOpen: null
        }
},
    [ServiceActions.FILTER_ACTION] : (state,action) => {       
        return { ...state, filterMap: action.payload }
    },
    [Actions.CHANGE_APP_MODE] : (state,action) => {
        //const [...apps] = state.apps
        try {
            const res = changeMode(state, action, state.apps, action.payload.app)
            return (res == null) ? inspHandlers( state, action) : { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.ADD_APP] : (state,action) => {
        const [...apps] = state.apps;
        apps.push({ 
            name: '<New>', 
            env: '<<Please Enter|Select>>',
            state: Actions.STATES.NEW,
            editState: Actions.EDIT_STATES.START,
            mode: Actions.MODES.EDIT,
            inspections: [],
            new: true,   // To ensure it is not pruned .... see SYNC_DDB
        })
        return { ...state, apps, keepOpen: { index: apps.length-1}};
    },
    [Actions.DELETE_APP] : (state,action) => {
        try {
            const res = deleteItem(state, action, state.apps, action.payload.app)
            return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.CONFIRM_DELETE_APP] : (state,action) => {
        return { ...state, apps: [...state.save.confirm], keepOpen: null, save: null};
    },
    [Actions.ABORT_DELETE_APP] : (state,action) => {
        return { ...state, apps: [...state.save.abort], save: null};
    },
    [Actions.EDIT_APP] : (state,action) => {
        try {
            const res = editItem(state, action, state.apps, action.payload.app)
            return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.EDIT_CONTINUE] : (state,action) => {
        try {
            const res = editItemContinue(state, action, state.apps, state.save.app)
            return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.EDIT_DISCARD] : (state,action) => {
        try {
           const res = editItemDiscard(state, action, state.apps, state.save.app)
           return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.VALIDATE] : (state,action) => {
        try {
            const res = validateItem(state, action, state.apps, action.payload.app)
            return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.API_EXCEPTION] : (state,action) => {
        try {
            const res = apiException(state, action, state.apps, action.payload.item, action.payload.fault)
            return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
        } catch (e) {
            console.log(e)
            return state;
        }
    },
    [Actions.CLEANUP] : (state,action) => {
        try {
            const res = cleanup(state, action, state.apps, action.payload)  // payload is the app
            return { ...state, apps: res.items, keepOpen: res.keepOpen, save: res.save};
         } catch (e) {
             console.log(e)
             return state;
         }
    },
    [Actions.SAVING] : (state,action) => {
        return { ...state, isLoading: true}
    },
    [ServiceActions.SYNC_DDB] : (state,action) => {
        var apps = [...state.apps]
        // Update Root items - then let instrument reducer handle rest
        const rootItems = action.payload.apps;
        var defaultActive = -1
        rootItems.forEach((item, index) => {
            var isDefault = false
            try {
                const open = util.valueOf("filterMap.open.name",state) // Default accordian panel to open
                if (open && util.root(open) === item.FQN && util.valueOf("filterMap.open.env", state) === item.Environment) {
                    defaultActive = index
                    isDefault = true
                }
                const found = findItemInList(apps, item.FQN, item.Environment)
                apps[found.index] = util.objectId({ ...found.item, state: item.state, env: item.Environment, timestamp: item.timestamp, keep: true, isDefault }) 
            } catch (e) { // If not found - must be an app created by another GUI instance 
                            // or directly via s3 entry and/or DDB entry (via AWS console)
                apps.push(util.objectId({name: item.FQN, state: item.state, env: item.Environment, timestamp: item.timestamp, inspections: [], keep: true, isDefault}))
            }
        });

        // Prune apps that are not marked with keep, or new (being created in this session), i.e. they must have been deleted, if no longer in the DDB table
        // Later when using DDB stream API - should get deletion notification and not need to do this pruning
        apps = apps.filter( app => {
            const keep = app.keep || app.new
            app.keep=false // reset for next SYNC_DDB
            return keep
        })

        // Handle lower items
        const _state = inspHandlers( {...state, apps}, { ...action, payload: action.payload.children })
        return { ..._state, isLoading: false, filterData: action.payload.filterData, defaultActive}
    },
    [ServiceActions.INSERT_DDB] : (state,action) => {
        var apps = [...state.apps]
        const entry = action.payload

        // Ensure state has a value
		if (entry.state === undefined) {
            entry.state = Actions.STATES.NEW
        }

        // Traverse to parent and insert 
        const parent = util.traverseApps(apps, util.parentName(entry.name), entry.env)

        // If the item was inserted/added by this dashboard instance there will be an entry already
        try {
            const found = findItemInList(parent.inspections, entry);
            parent.inspections[found.index] = { ...parent.inspections[found.index], ...entry}
        } catch ( err) { // Item not found, insert it
            entry.inspections = []
            parent.inspections.push(entry)
        }

        return { ...state, apps, isLoading: false }
    },
    [ServiceActions.MODIFY_DDB] : (state,action) => {
        var apps = [...state.apps]
        const entry = action.payload

        // Ensure state has a value
		if (entry.state === undefined) {
            entry.state = Actions.STATES.NEW
        }
        

        // Traverse to item and mutate
        const item = util.traverseApps(apps, entry.name, entry.env)
        Object.assign(item, entry)  // Merge new changes into item

        return { ...state, apps, isLoading: false }
    },
    [ServiceActions.REMOVE_DDB] : (state,action) => {
        var apps = [...state.apps]
        const entry = action.payload

        // Traverse to parent and remove child from inspections array
        const parent = util.traverseApps(apps, util.parentName(entry.name), entry.env)
        try {
            const found = findItemInList(parent.inspections, entry, entry.env)
            parent.inspections.splice(found.index, 1)
        } catch (err) {
            // Won't be found if item deleted in this instance of the dashboard - so do nothing here
        }
        return { ...state, apps, isLoading: false }
    },
    [_SYNC_ITEM_S3] : (state,action) => {

        // Handle only if a root item - or an application level item that the dashboard recognises
        // For now there is no such level s3 item - so, for now, just pass on to instruments 
        return inspHandlers( state, action)
    },
}

export default (state = initialState, action) => {
	
	if (action.type in handlers) {
		return handlers[action.type](state,action);
    }
    
    // Instrument reducers - because of hierarchical nature of the state structure
    // this needs to be done this way - and cannot be added to the main reducer
    // Only individual instruments (...future instruments) need to be added to the main 
    // reducer.
    return inspHandlers( state, action)
}