import React from 'react'
import CodeTag from 'components/basic/CodeTag'




export default () => (
    <div className='doc' style={{padding:'20px'}}>
<h2>Endpoint Instrument</h2>

<p>The endpoint instrument, tests connectivity of an endpoint as well as determines response time. The inspection can be configured to periodically execute via cron expressions. Each execution/measurement is stored as a timestamped sequence of measurements over time. This sequence is limited to a MAX_N (currently 10, but could change in future), older measurements get dropped off for new measurements.</p>

<h3>Overview</h3>

<p>The following diagram, highlighted in the green part, briefly describes the components and interaction for this instrument.</p>

<p><img src="images/AMSDashboard-Overview.jpg" alt="Endpoint Instrument" /></p>

<h3>View Mode</h3>

<p>In view mode a graph is displayed, which indicates the response time for the last, up to MAX_N, iterations.</p>

<p><img src="images/endpoint-graph.jpg" alt="Endpoint Graph" /></p>

<blockquote>
  <p>As for all instruments, the configuration data, is displayed in View mode. This is in the flattened JSON form. However it is collapsed, to view press the <img src="images/eye.jpg" alt="eye icon" /></p>
</blockquote>

    </div>
)