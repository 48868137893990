/*
 * Form component wrappers which include validation error display functionality
 */
import React, { Component} from 'react'
import { Form, Sidebar, Segment, Label, Button, Icon, Grid, Header, Image } from 'semantic-ui-react'
import './input.css'

//import Doc from '../../instruments/endpoint/doc/schedule'

const getConditionalProps = (errorMssg) => {
  const props = {};
  // fill your `invalid` variable in this function or pass it to it
  if (errorMssg !== undefined) {
      props.error = true;
  }
  return props
}

/*
const HorizontalSidebar = ({ animation, direction, visible }) => (
  <Sidebar as={Segment} animation={animation} direction={direction} visible={visible}>
    <Grid textAlign='center'>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as='h3'>New Content Awaits</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid columns={3} divided>
        <Grid.Column>
          <Image src='/images/wireframe/media-paragraph.png' />
        </Grid.Column>
        <Grid.Column>
          <Image src='/images/wireframe/media-paragraph.png' />
        </Grid.Column>
        <Grid.Column>
          <Image src='/images/wireframe/media-paragraph.png' />
        </Grid.Column>
      </Grid>
    </Grid>
  </Sidebar>
)
*/


export class LabelInfo extends Component { 
  state = {
    visible: false,
  }

  toggle = () => () =>
    this.setState(prevState => ({ visible: !prevState.visible }))

  render = () => {
    const { visible } = this.state
    const { label = false, iconEnabled = true, iconSize = 'small', children, style = {}} = this.props
  
    return (
      <div style={{ display:'inline-flex', ...style}} >
        {label ? label : null}
        {iconEnabled ? <Icon onClick={this.toggle()}                                                                                                                                                                                                                                                                       style={{float:'right'}} size={iconSize} name='info circle' link/> : null }
        <Sidebar as={Segment} style={{maxHeight:'50%'}} animation='push' direction='bottom' visible={visible} >
            <Icon className='doc-icon' onClick={this.toggle()} size='large' name='window close outline' link/>
            {children}
        </Sidebar>
      </div> 
    )
  }
}

// Note - when wrapping a form element in div, need to add the class field
const common = (Component, {cn1, cn2, errorMssg, width, info, label, ...other}) => {
  //console.log("info: ", info)

  return (
  <div width={width} className={cn1 ? cn1 : ''} >
    <Component label={ info ? <LabelInfo label={<label>{label}</label>}>{info}</LabelInfo> : label} className={cn2 ? cn2 : ''} {...other} {...getConditionalProps(errorMssg)}  />
    { errorMssg ? 
        <p className='error-message'>
            <span className='error-text'>{errorMssg}</span>
        </p> : null
    }
  </div>
)} 


export const FormInput = props => common(Form.Input, props)
export const FormTextArea = props => common(Form.TextArea, props)
export const FormGroup = props => common(Form.Group, props)
export const FormSelect = props => common(Form.Select, props)

export const MLabel = props => common(Label, props)
