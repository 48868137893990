import React from 'react'
import { Segment, Form, Checkbox } from 'semantic-ui-react'
import { FormInput, FormTextArea, FormGroup} from '../../basic/input/form'
import * as Actions from './actions'
import * as Util from '../../../util'
import { error } from '../../dashboard/common'
import ScheduleForm from '../scheduleForm'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
    };
    const { app, item, actions, dashboard} = props   // item is app - if root level, else inspection instance
    const data = item.data || {}
    // Merge with any data changed so far
    const level = Util.level(item.name)
    const save = dashboard.inspection.levels[level-1].save
    const merged = save ? { ...data, ...save.changed } : data
    const parent = Util.traverse(dashboard, Util.parentName(item.name), app.env)
    const set = (field, value) => actions.editInsp(parent, item, { att: field, value: value})  
    return (
        <div style = {{...styles}}>
           <ScheduleForm dashboard={dashboard} merged={merged} level={level} set={set} fields={Actions.FIELDS} />
            <Segment color='blue'>
                <label>Bastions</label>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormInput {...error(dashboard,Actions.FIELDS.BASTIONS_MIN, level)} type='number' label='Min' placeholder='Min # of bastions' value={merged[Actions.FIELDS.BASTIONS_MIN]} onChange={(e, { value }) => set(Actions.FIELDS.BASTIONS_MIN, value)}n/>
                        <FormInput {...error(dashboard,Actions.FIELDS.BASTIONS_MAX, level)} type='number' label='Max' placeholder='Max # of Bastions' value={merged[Actions.FIELDS.BASTIONS_MAX]} onChange={(e, { value }) => set(Actions.FIELDS.BASTIONS_MAX, value)}n/>
                    </Form.Group>
                </Form>   
            </Segment>
            <Segment color='yellow'>
                <label>Other</label>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormInput {...error(dashboard,Actions.FIELDS.OTHER_MIN, level)} type='number' label='Min' placeholder='Min # of EC2s' value={merged[Actions.FIELDS.OTHER_MIN]} onChange={(e, { value }) => set(Actions.FIELDS.OTHER_MIN, value)}n/>
                        <FormInput {...error(dashboard,Actions.FIELDS.OTHER_MAX, level)} type='number' label='Max' placeholder='Max # of EC2s' value={merged[Actions.FIELDS.OTHER_MAX]} onChange={(e, { value }) => set(Actions.FIELDS.OTHER_MAX, value)}n/>
                    </Form.Group>
                </Form>   
            </Segment>
        </div>
    )
})