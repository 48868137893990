import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import InputWithDropdown from '../basic/input/InputWithDropdown'
import * as Actions from './actions'
import EditFlows from './editFlows'
import BasicInput from '../basic/input/basicInput'

import {COMMON_FIELDS, error, info, GENERAL} from './common'
import { LabelInfo } from '../basic/input/form'

import { stateStyle} from './RootPanelTitle'  // circle indicator
import { relName, traverse, parentName, isActive } from '../../util'

import { INSTRUMENTS } from '../instruments/instruments'
import BuildInstrumentDoc from '../instruments/doc/main'

import { useDrag, useDrop } from 'react-dnd'
import { util } from 'node-forge'

const INST_SELECTIONS = INSTRUMENTS.filter( (inst) => inst !== 'group')

const draggableStyle = {
  border: '1px dashed gray',
  //backgroundColor: 'white',
  padding: '10px',
  //padding: '0.5rem 1rem',
  //marginRight: '1.5rem',
  //marginBottom: '1.5rem',
  //float: 'left',
}

export const ItemTypes = {
  INSP: 'box',
}

export const Draggable = (props) => {
  const item = { insp: props.item, parent: props.parent, type: props.item.type /*ItemTypes.BOX*/ }
  const [{ opacity }, drag] = useDrag({
    item,
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        // Move inspection
        props.actions.moveInsp(item.insp, item.parent, dropResult.insp)
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })
  return (
    <div ref={drag} style={{ ...draggableStyle, ...props.style, opacity }}>
      {props.children}
    </div>
  )
}

const droppableStyle = {
  /*
  height: '12rem',
  width: '12rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  */
}

function selectBackgroundColor(isActive, canDrop) {
  if (isActive) {
    return 'blue'
  } else if (canDrop) {
    return 'cyan'
  } else {
    return 'white'
  }
}

function selectBorder(isActive, canDrop) {
  if (isActive) {
    return {  
      border: '3px solid blue',
      borderRadius: '5px',
    }
  } else if (canDrop) {
    return {  
      border: '2px solid cyan',
      borderRadius: '5px',
    }
  } else {
    return  { 
      border: 'none',
    }
  }
}

export const Droppable = (props) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: INSTRUMENTS, //ItemTypes.BOX,
    drop: () => ({
      insp: props.item 
    }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  //const backgroundColor = selectBackgroundColor(isActive, canDrop)
  const border = selectBorder(isActive, canDrop)
  return (
    <div ref={drop} style={{ ...props.style, ...border/*backgroundColor*/ }}>
      {props.children}
    </div>
  )
}

export const Normal = (props) => {
  return (
    <div style={{...props.style, display: 'inline'}}>
      {props.children}
    </div>
  )
}

export class SubPanelTitle extends Component { 
  state = {
    instrument: '',  // 
  }

  viewMode = (app, isEditable, insp, changeMode, dashboard) => {
    const parent = traverse(dashboard, parentName(insp.name), app.env)
    const COMP = insp.type === 'group' ? Droppable : Normal
    const isActiveColour = () => isActive(insp) ? 'green' : 'red'
    const iconColour = insp.type === 'group' ? 'blue' : isActiveColour()
    return (
      <COMP item={insp} style={{display: 'inline'}}>
        <Icon color={iconColour} name={ insp.type === 'group' ? 'object group outline' : 'stethoscope'}/>
        <span>{relName(insp.name)}</span>

        <div style={stateStyle[insp.state]}/> 
    
        { isEditable && app.mode === Actions.MODES.EDIT ?  // Check if in editable mode and parent - root level - is in edit mode
          <Icon onClick={ (e) => changeMode(parent, insp, Actions.MODES.EDIT) } style={{float:'right', marginRight:'20px'}} name='edit outline' /> : null
        }
        { insp.type !== 'group' ? 
          <span style={{float:'right',paddingRight: '30%'}}>{insp.type}</span> : null
        }
      </COMP>
    )
  }

  editMode = (app, insp, actions, dashboard, hasChildren, level) => {
    const _hasChildren = hasChildren || false
    const save = dashboard.inspection.levels[level-1].save
    const type = save && save.changed ? save.changed.type || insp.type : insp.type
    const parent = traverse(dashboard, parentName(insp.name), app.env)   
    const COMP = insp.type !== 'group' ? Draggable : Normal
    // Obtain move to if dragging
    const moveTo = save && save.moveTo ? save.moveTo : undefined  // If dragging will be set else make undefined
    return (
      <COMP actions={actions} parent={parent} item={insp} style={{ /*display : 'inline'*/}}>    
        <BasicInput name={COMMON_FIELDS.NAME} onChange={(e)=> {
            // We are only changing the relative name - but name must be the fully qualified name
            // It is changed to fully qualified i.e. include parent name component on saving to b/end
            actions.editInsp(parent, insp, { att: COMMON_FIELDS.NAME, value: e.target.value})
          }
        } placeholder={relName(insp.name)} {...error(dashboard,COMMON_FIELDS.NAME, level)} />
        { insp.type !== 'group' ? <InputWithDropdown onClick={(e)=> {
              this.setState({ instrument : e.target.textContent})
              actions.editInsp(parent, insp, { att: COMMON_FIELDS.TYPE, value: e.target.textContent})
          }} label={<LabelInfo style={{ paddingRight: '10px' }} label='Instrument' >{<BuildInstrumentDoc />}</LabelInfo>} style={{paddingLeft: '10%'}} val={type} selections={INST_SELECTIONS} hdr='Type' /> : null
        }
        <EditFlows moveTo={moveTo} {...this.props} {...info(GENERAL, this.state.instrument || insp.type)} item={insp} deleteEnabled={!_hasChildren} parent={parent} />
      </COMP>
    )
  }

  render = () => {
    const { app, isEditable, insp, mode, actions, dashboard, hasChildren, level} = this.props
    return (isEditable && mode === Actions.MODES.EDIT) ? this.editMode(app, insp, actions, dashboard, hasChildren, level) : this.viewMode(app, isEditable, insp, actions.changeMode, dashboard)
  }
}

export default connect(null, null)(SubPanelTitle)