
//import AWS from 'aws-sdk'
import * as util from '../../util'
import * as Config from '../../config'
//import Instruments from '../instruments/instruments'
//import { _SYNC_ITEM_S3} from './common'
import * as Services from '../../services/services'

// Actions
export const CHANGE_APP_MODE = 'change_app_mode'

export const ADD_APP = 'add_app'
export const DELETE_APP = 'delete_app'

export const CONFIRM_DELETE_APP = 'confirm_delete_app'
export const ABORT_DELETE_APP = 'abort_delete_app';

export const EDIT_APP = 'edit_app'
export const EDIT_CONTINUE = 'edit_continue'
export const EDIT_DISCARD = 'edit_discard'

//export const SAVE_APP = 'edit_app'
export const VALIDATE = 'validate_app'
export const API_EXCEPTION = 'api_exception' // Something failed in backend request
//export const VALIDATION_FAILED = 'validation_failed'
//export const COMMIT =   'commit_app'  // Send to back end

/*
export const SYNC_DDB = 'sync-ddb'    // Sync with dynamo db table
export const SYNC_DDB_ERR = 'sync-ddb-err'    // Cannot sync with dynamo db
*/

export const SAVING = 'saving'   // Indicates busy

//export const SYNC_ITEM_S3 = 'sync-item-s3'    // Sync an app/inspection with its config data in S3

export const CLEANUP = 'cleanup'      // Clear any edit changes

// APP/INSPECTION States
export const STATES = {
    GREEN:  1,
    AMBER:  2,
    RED:    3,
    NEW:    4,
}

// EDIT States
export const EDIT_STATES = {
	START:  0,         // In edit mode but no changes have been made
	DELETE: 1,		   //      "  and delete clicked
	SAVE:   2,		   //      "  and a change to app or Inspection entry has been made
	CANCEL: 3,         // Confirm/Abort changes made - goes into this phase when user
					   // tries to move to another app while editing an app, or 
					   // similarly with an inspection  
	FAILED: 4,		   // Validation failed
	EDITED: 5,		   // Being edited
	FAULT: 	6,         // General Fault - API Exception 
	MOVE: 	7,		   // Move to another parent (app or group) - Only group supported for now

	// Activate/Deactivate Time based (cloudwatch event) inspection
	RUN: 	8,		    // Activate cloudwatch event
	STOP: 	9, 			// Deactivate    "
}

// MODES e.g. edit/view
export const MODES = {
    EDIT: 0,
    VIEW: 1,
}

// Root level fields
export const FIELDS = {
	NAME : 'name',
    ENVIRONMENT : 'env',
}

export const changeAppMode = (app, mode) => {
	return (dispatch, getState) => {
		// If app is in being edited, do validation flow
		const edited = app.editState === EDIT_STATES.EDITED || app.editState === EDIT_STATES.FAILED
		if (edited) {
			// Do validation dispatch
			dispatch({
				type : VALIDATE,
				payload : { app: app, mode: mode }
			})

			// Check if validation passed
			const save =  getState().dashboard.save
			if (save && save.invalid !== null) {
				// Failed - exit
				return
			}
		} 
		
		dispatch({
			type : CHANGE_APP_MODE,
			payload : { app: app, mode: mode }
		})
		
	}
}

export const editApp = (app, change) => {
	return {
		type : EDIT_APP,
		payload : { app: app, change: change }
	}
}

export const addApp = () => {
	return {
		type : ADD_APP,
	}
}

export const deleteApp = (app) => {
	return {
        type : DELETE_APP,
        payload: { app }
	}
}

export const confirmDeleteApp = (app) => {

	return (dispatch, getState) => {

		dispatch({
			type : SAVING,   // Indicate we are saving ... to show busy
		})

		// Construct REST call body
		const body = {
			"name" : app.name,
			"env": app.env
		}

		// Fire rest call to APIGateway
		var pathParams = {
			//This is where path request params go. 
		};
		// Template syntax follows url-template https://www.npmjs.com/package/url-template
		var pathTemplate = `/${Config.API_STAGE}/app-manage`
		var method = 'DELETE';
		
		var additionalParams = {
			//If there are query parameters or headers that need to be sent with the request you can add them here
			headers: {
				/*"Accept": "text/plain",*/
				"Content-Type": "application/json"
			},/*
			queryParams: {
				param0: '',
				param1: ''
			}*/
		};
				
		Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
			.then(function(result){
				// Changes committed to backend - do cleanup - the changes should be recieved from the backend 
				dispatch({
					type : CONFIRM_DELETE_APP,
				})
			}).catch( function(result){
				//This is where you would put an error callback
				console.log('failed')
				dispatch({
                    type : API_EXCEPTION,
                    payload : { fault: result, item: app }
                })
			}
		);
		
	}
}

export const abortDeleteApp = (app) => {
	return {
        type : ABORT_DELETE_APP,
	}
}

export const editContinue = (app) => {
	return { type: EDIT_CONTINUE }
}

const toggle = (mode) => mode === MODES.EDIT ? MODES.VIEW : MODES.EDIT

// Use thunk to fire 2 actions sequentially
export const editDiscard = () => {
	return (dispatch, getState) => {
		// application entry we want to navigate to
		const desired = getState().dashboard.save.desired
		const current = getState().dashboard.save.app

		// Dispatch discard
		dispatch({ type: EDIT_DISCARD})

		// View/Edit current app
		dispatch({
			type : CHANGE_APP_MODE,
			payload : { app: current, mode: toggle(current.mode) }
		})

		// View/Edit desired
		dispatch({
			type : CHANGE_APP_MODE,
			payload : { app: desired, mode: toggle(desired.mode) }
		})

	}
}

/******  TODO Only preliminary - replace with login functionality - cannot have credentials like this - static website - remove hardcoding *****/
// also this is duplicated in instruments/endpoint/action.js
/*
var apigClientFactory = require('aws-api-gateway-client').default;

const apigClient = apigClientFactory.newClient(Config.API_ACCESS);
*/

const createApp = (app, name, env, dispatch) => {
		// Construct REST call body
		const body = {
			"name" : name,
			"env": env
        }

        // Fire rest call to APIGateway
        var pathParams = {
            //This is where path request params go. 
        };
        // Template syntax follows url-template https://www.npmjs.com/package/url-template
		//Dev var pathTemplate = '/dev/app-manage'
		var pathTemplate = `/${Config.API_STAGE}/app-manage`
        var method = 'PUT';
        
        var additionalParams = {
            //If there are query parameters or headers that need to be sent with the request you can add them here
            headers: {
                /*"Accept": "text/plain",*/
                "Content-Type": "application/json"
            },/*
            queryParams: {
                param0: '',
                param1: ''
            }*/
        };

		//const axios = require('axios')
		//axios.defaults.withCredentials = false;
        Services.Clients.APIGateway.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(function(result){
				// Changes committed to backend - do cleanup - the changes should be recieved from the backend 
				dispatch({
					type : CLEANUP,
					payload : app  // name will be different, if this is a new inspection --- cleanup needs to handle
				})

				dispatch({
					type : CHANGE_APP_MODE,
					payload : { app, mode: MODES.VIEW }
				})
            }).catch( function(result){
                //This is where you would put an error callback
				console.log('failed')
				dispatch({
                    type : API_EXCEPTION,
                    payload : { fault: result, item: app }
                })
			}
		);

}

export const save = (parent, app) => {  // parent should be undefined as it is root

	return (dispatch, getState) => {

		dispatch({
			type : SAVING,   // Indicate we are saving ... to show busy
		})

		// Do asynchronously
		const tout = setTimeout(() => {

			// Dispatch rest call - which adds to s3 or updates s3
			const level = util.level(app.name)

			const state = getState().dashboard
			const changed = level === 0 ? state.save.changed : state.inspection.levels[level-1].save.changed 

			// Merge what's changed with the existing data
			//const raw = { ...app.data, ...changed}

			// Should only go to DDB if a new app - at the moment there is no further data on app root level - if there is in the future
			// it should also probably be stored in the DDB table
			if (app.name === "<New>" && changed.name !== undefined ) { // Validation flow (TODO) should have picked up ... fact that name has not been entered
				return createApp(app, changed.name, changed.env, dispatch)
			}
			//console.log("TODO .... write to DDB")

			// Changes committed to backend - do cleanup - the changes should be recieved from the backend 
			dispatch({
				type : CLEANUP,
				payload : app  // name will be different, if this is a new inspection --- cleanup needs to handle
			})

			dispatch({
				type : CHANGE_APP_MODE,
				payload : { app, mode: MODES.VIEW }
			})

			clearTimeout(tout)	
		}, 0)

	}
}

