import React from 'react'
import { Dropdown, Input, Form } from 'semantic-ui-react'
import * as Util from '../../../util'

export default ({label, val, selections, hdr, onClick, errorMssg, style, ...other}) => {
  return (
    <div style={{...style, display: 'inline-flex', flexDirection: 'row'}} className='input-with-error'>
      {label}
      <Dropdown {...Util.getConditionalProps(errorMssg)} closeOnBlur={false} closeOnChange={false} {...other} text={val == null ? '<New>' : val} onAddItem = {(e,val)=> alert('onAddItem')}  onChange = {(e,val)=> alert('onChange')}> 
        <Dropdown.Menu  >
          {/*<Dropdown.Header content={hdr} />
          <Input placeholder='<New>' name='new' /> {*//* set Dropdown opne={true} when clicked in to enter text - to stop it from closing - on enter - use onKeyDown and the check for keyCode == 13 - remove open*//*}
          */}

          <Dropdown.Divider />
          {selections.map( item => (
              <Dropdown.Item key={item} active={val === item ? 'true': 'false'} text={item} value={item} onClick={onClick} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      { errorMssg ? 
          <p className='error-message'>
            ! {errorMssg}
          </p> : null
      }
    </div>

    
  )
}