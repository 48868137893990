
import { traverse, traverseApps, parentName, isRoot, findItemInList } from '../../util'

// Common sync S3 code
export const commonSyncS3 = (state, name, env, data, translate, instrument) => {
    // traverse to items parent
    var parent
    try {
        parent = {...traverse(state, parentName(name), env)}
    } catch(err) {

    debugger;
        console.error("Parent not found for ",name, env)
        return { ...state};
    }
    const inspections = [...parent.inspections]
    try {

        const found = findItemInList(inspections, name, env)
        //console.log(found)
        inspections[found.index] = { ...found.item, type: instrument, data: translate(data) } 
    } catch (e) { 
        // Must exist - something terribly wrong if not
        console.error("Something wrong: ", e)
    }
    // If parent is the root item - update apps --- if not its a 3rd level item, or lower 
    // and needs to be updated progressively upwards 
    parent.inspections = inspections
    const apps = [...state.apps]
    if (isRoot(parent)) {
        try {
            const found = findItemInList(apps,parent)
            apps[found.index] = parent
            //console.log(found)
        } catch(e) {
            console.error("Something wrong: ", e)
        }
    } else {
        // Get parents parent inspections
        try {
            const pp = traverseApps(apps, parentName(parent.name), env)  // parents parent
            pp.inspections = [...pp.inspections]
            const found = findItemInList(pp.inspections,parent,env)
            pp.inspections[found.index] = parent
        } catch(e) {
            console.error("Something went wrong: ", e)
        }
    }

    return { ...state, apps};
}