import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const ModalBasicExample = (props) => (
  <Modal closeOnEscape={false} closeOnDimmerClick={false} dimmer='inverted' defaultOpen={true} size='small'>
    <Header icon={{name: 'exclamation triangle', color: 'yellow'}} content={props.hdr} />
    <Modal.Content dangerouslySetInnerHTML={{__html: props.message}}>
      {props.children}
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.actionOK} color='green' inverted>
        <Icon name='checkmark' /> OK
      </Button>
    </Modal.Actions>
  </Modal>
)

export default ModalBasicExample