import React from 'react'
import { Icon } from 'semantic-ui-react'
import * as Actions from './actions'
import YesNoPrompt from '../basic/yesNoPrompt'
import ErrorPrompt from '../basic/errorPrompt'

import { stateStyle} from './RootPanelTitle'
import { LabelInfo } from '../basic/input/form';

import * as util from '../../util'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
        /*
        height: '68px',
        width: '100%',
        backgroundColor: '#FFFFFF',
        boxShadow: 'inset 0 1px 0 0 #D9D9D9',
        marginTop : '20px'
        */
    };
    var { app, item, actions, deleteEnabled, parent, moveTo, info='TODO.... @@@@@@'} = props   // item is app - if root level, else inspection instance
    // Determine if root level - i.e. application level, or inspection level
    const isRoot = app===item
    const itemType = isRoot? 'Application' : 'Inspection'

    const isActive = util.isActive(item)

    if (parent === undefined) {
        parent = app
    }

    return (
        <span style = {{...styles}}>
            <LabelInfo style={{float:'right', marginLeft:'10px', display:'inline!important'}} iconSize='large' >{info}</LabelInfo>
            <Icon onClick={ (e) => isRoot ? actions.changeMode(app, Actions.MODES.VIEW) : actions.changeMode(parent, item,Actions.MODES.VIEW) } style={{float:'right', marginLeft:'10px'}} name='save outline' />
            { !isRoot && item.editState !== Actions.EDIT_STATES.EDITED ? // Duplication Icon
                <Icon onClick={ (e) => actions.copyInsp(parent, item) } style={{float:'right', marginLeft:'10px'}} name='copy outline' /> : null
            }
            { !isRoot && item.type !== 'group' ? 
                <Icon onClick={ isActive ? (e) => actions.stopInsp(parent, item) :  (e) => actions.runInsp(parent, item) } style={{float:'right', marginLeft:'10px'}} color={isActive?'red':'green'} name={isActive?'stop':'play'} /> : null
            }
            { item.type === 'group' ? 
                <div style={{border: '1px solid grey',float:'right', marginLeft:'10px'}}>
                    <Icon onClick={ (e) => actions.stopInsp(parent, item) } style={{marginRight: '5px', marginLeft: '5px'}} color='red' name='stop' /> 
                    <Icon onClick={ (e) => actions.runInsp(parent, item) } style={{marginRight: '5px', marginLeft:'5px'}} color='green' name='play' />
                </div> : null
            }
            { deleteEnabled ?
                <Icon onClick={ (e) => isRoot ? actions.deleteApp(app) : actions.deleteInsp(parent, item) }style={{float:'right', marginLeft:'30px'}} color='red' name='minus circle' /> : null
            }
            {/*<div name='==status' style={stateStyle[item.state]} /> */}         
            { item.editState === Actions.EDIT_STATES.DELETE ? <YesNoPrompt 
                    hdr={`Delete ${itemType}`} 
                    icon='minus circle' 
                    message={`Are you sure you want to delete the ${itemType}?`}
                    actionNo={ (e) => actions.deleteAbort(parent, item) }          // item will be ignored on app level
                    actionYes={ (e) => actions.deleteConfirm(parent, item) }       //     "
            /> : null}
            { item.editState === Actions.EDIT_STATES.CANCEL ? <YesNoPrompt 
                    hdr={`Discard ${itemType} changes`} 
                    icon='minus circle' 
                    message='Are you sure you want to discard the changes?'
                    actionNo={ (e) => actions.editContinue(parent, item) }               // app argument will be ignored on app level
                    actionYes={ (e) => actions.editDiscard(parent, item) }               //      "  
            /> : null}
            { item.editState === Actions.EDIT_STATES.SAVE ? <YesNoPrompt 
                    hdr={`Save ${itemType} changes`} 
                    icon='save outline' 
                    message='Do you want to save the changes?'
                    actionNo={ (e) => actions.editContinue(parent, item) }
                    actionYes={ (e) => actions.save(parent, item) }    
            ><p>Cannot be undone!</p></YesNoPrompt> : null}
            { item.editState === Actions.EDIT_STATES.FAILED ? <ErrorPrompt 
                    hdr={`${itemType} validation failed`} 
                    message='Press OK to continue'
                    actionOK={ (e) => actions.editContinue(parent, item) }    
            ></ErrorPrompt> : null}
            { item.editState === Actions.EDIT_STATES.FAULT ? <ErrorPrompt 
                    hdr={`${itemType} - Request Failed`} 
                    message={`<pre>${item.fault}</pre><p>Press OK to continue</p>`}
                    actionOK={ (e) => actions.editContinue(parent, item) }    
            ></ErrorPrompt> : null}
            { item.editState === Actions.EDIT_STATES.MOVE ? <YesNoPrompt 
                    hdr={`Move ${item.name} to ${moveTo ? moveTo.name : ''}`} 
                    icon='minus circle' 
                    message='Are you sure you want to move?'
                    actionNo={ (e) => actions.abortMoveInsp(parent, item) }               // app argument will be ignored on app level
                    actionYes={ (e) => actions.confirmMoveInsp(parent, item) }               //      "  
            /> : null}
            { item.editState === Actions.EDIT_STATES.STOP ? <YesNoPrompt 
                    hdr={`Move ${item.name} to ${moveTo ? moveTo.name : ''}`} 
                    icon='stop circle outline' 
                    message='Are you sure you want to deactivate this inspection?'
                    actionNo={ (e) => actions.abortDeactivateInsp(parent, item) }               // app argument will be ignored on app level
                    actionYes={ (e) => actions.confirmDeactivateInsp(parent, item) }               //      "  
            /> : null}
        </span>
    );
});