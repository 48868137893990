
// Add Instruments here
export const INSTRUMENTS = [
    'group',    // Special type of inspection
    'endpoint',
    'ec2-check',
    'ec2-status-change',
    'sqs-depth',
    'rds-status'
    //... 
]

// Returns a promise ... as requires are asynch
export const InstrumentDocs = new Promise ( (resolve, reject) => {
    const docs = INSTRUMENTS.reduce( (acc, cur, ind, arr) => {
        console.log("Got here 1")
        try {
    //debugger;
            console.log ("Got here 2")
            acc = {...acc, ...require(`./${cur}/doc/docs`).default }
            console.log("ACC: ",acc)
        } catch (err) {
            console.warn("Docs not found for instrument: ", cur)
              // Ignore exception ... doc not found
        }
        console.log(`Ind ${ind} length: ${arr.length}`)
        if (ind===arr.length-1) {
            resolve(acc)
        } 
        return acc
    }, require(`./doc/docs`).default)
})


const plugins = {
    views: {},
    forms: {},
    actions: {},
    reducers: {},
    validators: {},
}
INSTRUMENTS.forEach(instrument => {
    plugins.views[instrument] = require(`./${instrument}/view`).default
    plugins.forms[instrument] = require(`./${instrument}/form`).default
    plugins.actions[instrument] = require(`./${instrument}/actions`)
    plugins.reducers[instrument] = require(`./${instrument}/reducers`).default
    plugins.validators[instrument] = require(`./${instrument}/validators`).default
})

export default plugins

